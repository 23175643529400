import minorAilmentsDataJson from './minorAilments.json';
import coronaDataJson from './corona.json';
import asthmaDataJson from './asthmaExa.json';
import coronavirusexaDataJson from './coronavirusexa.json';
import principleTrialExaDataJson from './principleTrialExa.json';

const removeConsentAndPersonalDetailsPages = (form) => {
    const data = { ...form };
    // TODO: remove consent page and personaldetails based on valid condition
    data.pages.splice(0, 1);
    data.pages.splice(data.pages.length - 1, data.pages.length);
    
    return data;
}

const getMinorAilmentsFormData = () => {
    return removeConsentAndPersonalDetailsPages(minorAilmentsDataJson);
}

const getCoronaFormData = () => {
    return removeConsentAndPersonalDetailsPages(coronaDataJson);
}

const getAsthmaFormData = () => {
    return removeConsentAndPersonalDetailsPages(asthmaDataJson);
}

const getCoronavirusExaFormData = () => {
    return removeConsentAndPersonalDetailsPages(coronavirusexaDataJson);
}

const getPrincipleTrialExaFormData = () => {
    return removeConsentAndPersonalDetailsPages(principleTrialExaDataJson);
}

export {
    getMinorAilmentsFormData,
    getCoronaFormData,
    getAsthmaFormData,
    getCoronavirusExaFormData,
    getPrincipleTrialExaFormData,
}