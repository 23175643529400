import React from 'react';
import PropTypes from 'prop-types';
import FormElementsBuilder from './FormElementsBuilder';
import ContinueButton from './TriageContinueButton';

const TriagePages = (pages, changeHandler, continueClickHandler) => {
    if (!(pages && pages.length)) {
        return null;
    }

    const renderPage = (page, index, changeHandler, continueClickHandler) => {
        if (!page.fields) return null;
        return (
            <div className="page" key={index}>
                <FormElementsBuilder
                    fields={page.fields}
                    changeHandler={changeHandler}
                    formDataIndex={index} />
                <ContinueButton index={index} continueClickHandler={continueClickHandler} />
            </div>
        );
    }

    return (
        pages.map((page, index) => {
            return renderPage(page, index, changeHandler, continueClickHandler);
        })
    );
}

TriagePages.propTypes = {
    pages: PropTypes.array,
    changeHandler: PropTypes.func,
    continueClickHandler: PropTypes.func,
};
export default TriagePages;