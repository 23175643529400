import React, { useState, Fragment } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { LOCALE } from './constant';
import messages from './i18n/i18n';
import { StateProvider, initialState, reducer } from './context';
import FluWidgetView from './views/FluWidgetView';
import CoronaFormView from './views/Triage/CoronaFormView';
import MinorAilmentsFormView from './views/Triage/MinorAilmentsFormView';
import CoronavirusExaFormView from './views/Triage/CoronavirusExaFormView';
import PrincipleTrialExaFormView from './views/Triage/PrincipleTrialExaFormView';
import AsthmaFormView from './views/Triage/AsthmaFormView';

const App = () => {
  const [locale] = useState(LOCALE);

  const renderView = (View) => (
    ({ location }) => <View location={location} />
  );

  const renderApp = () => {
    return (
      <IntlProvider locale={locale} messages={messages[locale]} textComponent={Fragment}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/flu" component={FluWidgetView} />
            <Route exact path="/triage/minorailments" render={renderView(MinorAilmentsFormView)} />
            <Route exact path="/triage/corona" render={renderView(CoronaFormView)} />
            <Route exact path="/triage/coronavirusexa" render={renderView(CoronavirusExaFormView)} />
            <Route exact path="/triage/principletrialexa" render={renderView(PrincipleTrialExaFormView)} />
            <Route exact path="/asthmaexa" render={renderView(AsthmaFormView)} />
            <Route exact path="/" component={() => (<Redirect to="/flu" />)} />
          </Switch>
        </BrowserRouter>
      </IntlProvider>
    );
  }

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      {renderApp()}
    </StateProvider>
  );
}

export default App;
