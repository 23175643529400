import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import OutcomeWrapper from './Outcome/Elements/OutcomeWrapper';
import OutcomeTitle from './Outcome/Elements/OutcomeTitle';
import OutcomeBody from './Outcome/Elements/OutcomeBody';
import OutcomeButton from './Outcome/Elements/OutcomeButton';
import OutcomeButtonHeader from './Outcome/Elements/OutcomeButtonHeader';
import { redirectUrl } from '../../sideEffects/urlMapper';
import {
  AGE_GROUP_RESTRICTION,
} from '../../constant';
import Disclaimer from '../Forms/FormElements/Disclaimer';

const FluAgeGroupRestriction = ({ properties, submissionId }) => {
  const renderExit = (messageKey) => (
    <div className="cds-buttons">
      <OutcomeButton type="primary" messageKey={messageKey} link={window.__ENV.padotcomURL} />
    </div>
  );
  const registerUrl = redirectUrl('registration/create', submissionId, true);
  const loginUrl = redirectUrl('login', submissionId, true);
  const renderOutcomeButtons = (isEligible) => (
    <OutcomeWrapper>
      <OutcomeButtonHeader headerId="cds.result.button.header1">
        <OutcomeButton type="primary" messageKey={isEligible ? 'cds.result.button.register' : 'cds.result.button.register.book'} link={registerUrl} />
      </OutcomeButtonHeader>
      <OutcomeButtonHeader headerId="cds.result.button.header2">
        <OutcomeButton type="outline" messageKey={isEligible ? 'cds.result.button.signin' : 'cds.result.button.signin.book'} link={loginUrl} />
      </OutcomeButtonHeader>
    </OutcomeWrapper>
  )
  const renderUnderSixMonths = (ageGroupRestriction) => (
    <OutcomeWrapper>
      <OutcomeTitle id={`cds.flu.result.header.${ageGroupRestriction}`} defaultMessage="" />
      <OutcomeBody>
        <FormattedMessage id={`cds.flu.result.content.${ageGroupRestriction}`} values={{ br: <br /> }} defaultMessage="Something went wrong. Please try again later." />
      </OutcomeBody>
      {renderExit('cds.result.button.go.home.flu')}
    </OutcomeWrapper>
  );

  const renderSixMonthsToTwoYears = (ageGroupRestriction) => (
    <OutcomeWrapper>
      <OutcomeTitle id={`cds.flu.result.header.${ageGroupRestriction}`} defaultMessage="" />
      <OutcomeBody>
        <FormattedHTMLMessage
          tagName="div"
          id={`cds.flu.result.content.${ageGroupRestriction}`}
          defaultMessage="<p>Children under 2 years old are not routinely eligible for NHS flu vaccination.</p><p>However, some children with underlying health conditions such as diabetes or conditions affecting the lungs, heart, kidneys, liver, nervous system or immune system may be eligible from 1 September 2024 onwards.</p><p>If your child has an underlying health condition, please contact your GP practice to see if they need a flu vaccination.</p><p>Please also note that flu vaccination may not be suitable for:</p> <ul><li><p>People with a severe egg allergy.</p></li><li><p>People who have had a severe allergic reaction to a previous dose of flu vaccine. </p></li></ul>"
        />
      </OutcomeBody>
      {renderExit('cds.result.button.go.backtohome.flu')}
    </OutcomeWrapper>
  );

  const renderTwoYearsToTenYears = (ageGroupRestriction) => (
    <OutcomeWrapper>
      <OutcomeTitle id={`cds.flu.result.header.${ageGroupRestriction}`} defaultMessage="Eligible for a free NHS flu vaccination from 1 September 2024 onwards" />
      <OutcomeBody>
        <FormattedHTMLMessage
          tagName="div"
          id={`cds.flu.result.content.${ageGroupRestriction}`}
          defaultMessage="<p>All children aged 2 to 10 years (up to and including all year 6 pupils) will be offered the flu vaccine.</p> <p>Those aged 2-3 years (born between 1 September 2020 and 31 August 2022) will have their flu vaccination at their GP practice - please wait to be contacted by your GP practice.</p> <p>All primary school-aged children up to and including year 6 will have their flu vaccine at school. Please wait for the school-age immunisation service to contact you.</p> <p>Please also note that flu vaccination may not be suitable for:</p> <ul><li><p>People with a severe egg allergy.</p></li><li><p>People who have had a severe allergic reaction to a previous dose of flu vaccine. </p></li></ul>"
        />
      </OutcomeBody>
      {renderExit('cds.result.button.go.backtohome.flu')}
    </OutcomeWrapper>
  );

  const renderElevenYearsToFifteenYears = (ageGroupRestriction) => (
    <OutcomeWrapper>
      <OutcomeTitle id={`cds.flu.result.header.${ageGroupRestriction}`} defaultMessage="Eligibility advice" />
      <OutcomeBody>
        <FormattedHTMLMessage
          tagName="div"
          id={`cds.flu.result.content.${ageGroupRestriction}`}
          defaultMessage="<p>All school-aged children (including those home-schooled or in non-mainstream education) will be offered the flu vaccine from years 7 to 11 inclusive from 1 September 2024 onwards. Please wait to be contacted by your vaccine provider.</p>"
        />
      </OutcomeBody>
      {renderExit('cds.result.button.go.backtohome.flu')}
    </OutcomeWrapper>
  );

  const renderNhsFreeEligible = (ageGroupRestriction) => (
    <OutcomeWrapper>
      <OutcomeTitle id={`cds.flu.result.header.${ageGroupRestriction}`} defaultMessage="You are eligible for a free NHS flu vaccination from 3 October 2024 onwards" />
      <OutcomeBody>
        <FormattedHTMLMessage
          tagName="div"
          id={`cds.flu.result.content.${ageGroupRestriction}`}
          defaultMessage="<p>Your GP practice or the NHS will contact you to arrange your flu vaccination appointment. If you have not been contacted by end of October, please contact your GP practice.</p><p>Please also note that flu vaccination may not be suitable for:</p> <ul><li><p>People with a severe egg allergy.</p></li><li><p>People who have had a severe allergic reaction to a previous dose of flu vaccine. </p></li></ul>"
        />
        {
          ageGroupRestriction !== AGE_GROUP_RESTRICTION.OVERSIXTYFIVEYEARS && (
            <Disclaimer id={`cds.flu.result.content.${ageGroupRestriction}.disclaimer`}
              defaultMessage="<strong>Please note:</strong> <p>Pregnant women are eligible for an NHS flu vaccination from 1 September 2024. Please contact your practice if you are pregnant and have not heard from them by the end of September 2024.</p>" />
          )
        }
      </OutcomeBody>
      {renderOutcomeButtons(true)}
    </OutcomeWrapper>
  );

  const renderSixteenYearsToSeventeenYears = (ageGroupRestriction) => {
    const { mayBeEligibleForNhsFluVaccination, nhsFreeEligible, eligibleForNhsFluVaccination, notSuitableForNhsFluVaccination } = properties;
    if (mayBeEligibleForNhsFluVaccination) {
      return (
        <OutcomeWrapper>
          <OutcomeTitle id={`cds.flu.result.header.${ageGroupRestriction}.maybeeligible`} defaultMessage="You are eligible for NHS flu vaccination from 1 September 2024 onwards" />
          <OutcomeBody>
            <FormattedHTMLMessage
              tagName="div"
              id={`cds.flu.result.content.${ageGroupRestriction}.maybeeligible`}
              defaultMessage="<p>Your answers suggest that you are or may be eligible for free NHS flu vaccination.</p> <p>Please wait to be contacted by your GP practice. If you have not heard from them by end of September, please contact the practice.</p><p>Please also note that flu vaccination may not be suitable for:</p> <ul><li><p>People with a severe egg allergy.</p></li><li><p>People who have had a severe allergic reaction to a previous dose of flu vaccine. </p></li></ul> "
            />
            <Disclaimer id={`cds.flu.result.content.${ageGroupRestriction}.disclaimer`}
              defaultMessage="<strong>Please note:</strong> <p>Pregnant women are eligible for an NHS flu vaccination from 1 September 2024. Please contact your practice if you are pregnant and have not heard from them by the end of September 2024.</p>" />
          </OutcomeBody>
          {renderExit('cds.result.button.go.backtohome.flu')}
        </OutcomeWrapper>
      );
    }
    if (nhsFreeEligible) {
      return renderNhsFreeEligible(ageGroupRestriction);
    }
    if (eligibleForNhsFluVaccination) {
      return (
        <OutcomeWrapper>
          <OutcomeTitle id={`cds.flu.result.header.${ageGroupRestriction}.eligible`} defaultMessage="You are eligible for NHS flu vaccination from 3 October 2024 onwards" />
          <OutcomeBody>
            <FormattedHTMLMessage
              tagName="div"
              id={`cds.flu.result.content.${ageGroupRestriction}.eligible`}
              defaultMessage="<p>Your answers suggest that because of your work, you are eligible for an NHS flu vaccination.</p><p>This should be provided by your employer – please contact them for details.</p> <p>If you cannot get an NHS flu vaccination through your work, you may be able to get an NHS flu vaccination through your GP practice or through the NHS.</p><p>Please also note that flu vaccination may not be suitable for:</p> <ul><li><p>People with a severe egg allergy.</p></li><li><p>People who have had a severe allergic reaction to a previous dose of flu vaccine.</p> </li></ul>" />
            <Disclaimer id={`cds.flu.result.content.${ageGroupRestriction}.disclaimer`}
              defaultMessage="<strong>Please note:</strong> <p>Pregnant women are eligible for an NHS flu vaccination from 1 September 2024. Please contact your practice if you are pregnant and have not heard from them by the end of September 2024.</p>" />
          </OutcomeBody>
          {renderExit('cds.result.button.go.backtohome.flu')}
        </OutcomeWrapper>
      );
    }
    if (notSuitableForNhsFluVaccination) {
      return (
        <OutcomeWrapper>
          <OutcomeTitle id={`cds.flu.result.header.${ageGroupRestriction}.notsuitable`} defaultMessage="Flu vaccination is not suitable for you" />
          <OutcomeBody>
            <FormattedHTMLMessage
              tagName="div"
              id={`cds.flu.result.content.${ageGroupRestriction}.notsuitable`}
              defaultMessage="<p>Your answers suggest that you are not eligible for NHS flu vaccination.</p><p>In addition, flu vaccination cannot be given to people with a severe egg allergy or a history of severe reaction to a previous flu vaccination.</p>"
            />
          </OutcomeBody>
          {renderExit('cds.result.button.go.backtohome.flu')}
        </OutcomeWrapper>
      );
    }
    return (
      <OutcomeWrapper>
        <OutcomeTitle id={`cds.flu.result.header.${ageGroupRestriction}.noteligible`} defaultMessage="It looks like you are not eligible for a free NHS flu vaccination" />
        <OutcomeBody>
          <FormattedHTMLMessage
            tagName="div"
            id={`cds.flu.result.content.${ageGroupRestriction}.noteligible`}
            defaultMessage="<p>The answers you have given indicate that you are not eligible for free NHS flu vaccination. If you still think you might be eligible, please contact your pharmacist or speak to your GP practice.</p> <h1>Book a private flu vaccination </h1> <p>You can still get a private flu vaccination by booking an appointment with a pharmacy on Patient Access now.</p><p>If you are currently unwell with a fever, please wait until you are fully recovered before booking your appointment.</p>"
          />
        </OutcomeBody>
        {renderOutcomeButtons(false)}
      </OutcomeWrapper>
    );
  };

  const renderEighteenYearsToFortyNineYears = (ageGroupRestriction) => {
    const { nhsFreeEligible, eligibleForNhsFluVaccination, notSuitableForNhsFluVaccination } = properties;
    if (nhsFreeEligible) {
      return renderNhsFreeEligible(ageGroupRestriction);
    }
    if (eligibleForNhsFluVaccination) {
      return (
        <OutcomeWrapper>
          <OutcomeTitle id={`cds.flu.result.header.${ageGroupRestriction}.eligible`} defaultMessage="You are eligible for NHS flu vaccination from 3 October 2024 onwards" />
          <OutcomeBody>
            <FormattedHTMLMessage
              tagName="div"
              id={`cds.flu.result.content.${ageGroupRestriction}.eligible`}
              defaultMessage="<p>Your answers suggest that because of your work, you are eligible for an NHS flu vaccination.</p><p>This should be provided by your employer – please contact them for details.</p> <p>If you cannot get an NHS flu vaccination through your work, you may be able to get an NHS flu vaccination through your GP practice or through the NHS.</p><p>Please also note that flu vaccination may not be suitable for:</p> <ul><li><p>People with a severe egg allergy.</p></li><li><p>People who have had a severe allergic reaction to a previous dose of flu vaccine.</p> </li></ul>"
            />
            <Disclaimer id={`cds.flu.result.content.${ageGroupRestriction}.disclaimer`}
              defaultMessage="<strong>Please note:</strong> <p>Pregnant women are eligible for an NHS flu vaccination from 1 September 2024. Please contact your practice if you are pregnant and have not heard from them by the end of September 2024.</p>" />
          </OutcomeBody>
          {renderExit('cds.result.button.go.backtohome.flu')}
        </OutcomeWrapper>
      );
    }

    if (notSuitableForNhsFluVaccination) {
      return (
        <OutcomeWrapper>
          <OutcomeTitle id={`cds.flu.result.header.${ageGroupRestriction}.notsuitable`} defaultMessage="Flu vaccination is not suitable for you" />
          <OutcomeBody>
            <FormattedHTMLMessage
              tagName="div"
              id={`cds.flu.result.content.${ageGroupRestriction}.notsuitable`}
              defaultMessage="<p>Your answers suggest that you are not eligible for NHS flu vaccination.</p><p>In addition, flu vaccination cannot be given to people with a severe egg allergy or a history of severe reaction to a previous flu vaccination.</p>"
            />
          </OutcomeBody>
          {renderExit('cds.result.button.go.backtohome.flu')}
        </OutcomeWrapper>
      );
    }
    return (
      <OutcomeWrapper>
        <OutcomeTitle id={`cds.flu.result.header.${ageGroupRestriction}.noteligible`} defaultMessage="It looks like you are not eligible for a free NHS flu vaccination" />
        <OutcomeBody>
          <FormattedHTMLMessage
            tagName="div"
            id={`cds.flu.result.content.${ageGroupRestriction}.noteligible`}
            defaultMessage="<p>The answers you have given indicate that you are not eligible for free NHS flu vaccination. If you still think you might be eligible, please contact your pharmacist or speak to your GP practice.</p> <p><strong>If you live in Scotland or Northern Ireland, you will be eligible for a flu vaccine via the NHS.</strong></p> <h1>Book a private flu vaccination </h1> <p>You can still get a private flu vaccination by booking an appointment with a pharmacy on Patient Access now.</p><p>If you are currently unwell with a fever, please wait until you are fully recovered before booking your appointment.</p>"
          />
        </OutcomeBody>
        {renderOutcomeButtons(false)}
      </OutcomeWrapper>
    );
  };

  const renderFiftyYearsToSixtyFourYears = (ageGroupRestriction) => {
    const { nhsFreeEligible, eligibleForNhsFluVaccination, notSuitableForNhsFluVaccination } = properties;
    if (nhsFreeEligible) {
      return renderNhsFreeEligible(ageGroupRestriction);
    }
    if (eligibleForNhsFluVaccination) {
      return (
        <OutcomeWrapper>
          <OutcomeTitle id={`cds.flu.result.header.${ageGroupRestriction}.eligible`} defaultMessage="You are eligible for NHS flu vaccination from 3 October 2024 onwards" />
          <OutcomeBody>
            <FormattedHTMLMessage
              tagName="div"
              id={`cds.flu.result.content.${ageGroupRestriction}.eligible`}
              defaultMessage="<p>Your answers suggest that because of your work, you are eligible for an NHS flu vaccination.</p><p>This should be provided by your employer – please contact them for details.</p> <p>If you cannot get an NHS flu vaccination through your work, you may be able to get an NHS flu vaccination through your GP practice or through the NHS.</p><p>Please also note that flu vaccination may not be suitable for:</p> <ul><li><p>People with a severe egg allergy.</p></li><li><p>People who have had a severe allergic reaction to a previous dose of flu vaccine.</p> </li></ul>"
            />
            <Disclaimer id={`cds.flu.result.content.${ageGroupRestriction}.disclaimer`}
              defaultMessage="<strong>Please note:</strong> <p>Pregnant women are eligible for an NHS flu vaccination from 1 September 2024. Please contact your practice if you are pregnant and have not heard from them by the end of September 2024.</p>" />
          </OutcomeBody>
          {renderExit('cds.result.button.go.backtohome.flu')}
        </OutcomeWrapper>
      );
    }

    if (notSuitableForNhsFluVaccination) {
      return (
        <OutcomeWrapper>
          <OutcomeTitle id={`cds.flu.result.header.${ageGroupRestriction}.notsuitable`} defaultMessage="Flu vaccination is not suitable for you" />
          <OutcomeBody>
            <FormattedHTMLMessage
              tagName="div"
              id={`cds.flu.result.content.${ageGroupRestriction}.notsuitable`}
              defaultMessage="<p>Your answers suggest that you are not eligible for NHS flu vaccination.</p><p>In addition, flu vaccination cannot be given to people with a severe egg allergy or a history of severe reaction to a previous flu vaccination.</p>"
            />
          </OutcomeBody>
          {renderExit('cds.result.button.go.backtohome.flu')}
        </OutcomeWrapper>
      );
    }
    return (
      <OutcomeWrapper>
        <OutcomeTitle id={`cds.flu.result.header.${ageGroupRestriction}.noteligible`} defaultMessage="Eligibility advice" />
        <OutcomeBody>
          <FormattedHTMLMessage
            tagName="div"
            id={`cds.flu.result.content.${ageGroupRestriction}.noteligible`}
            defaultMessage="<p>The answers you have given indicate that you are not eligible for free NHS flu vaccination. If you still think you might be eligible, please contact your pharmacist or speak to your GP practice.</p> <p><strong>If you live in Scotland or Northern Ireland, you will be eligible for a flu vaccine via the NHS.</strong></p> <h1>Book a private flu vaccination </h1> <p>You can still get a private flu vaccination by booking an appointment with a pharmacy on Patient Access now.</p><p>If you are currently unwell with a fever, please wait until you are fully recovered before booking your appointment.</p>"
          />
        </OutcomeBody>
        {renderOutcomeButtons(false)}
      </OutcomeWrapper>
    );
  };

  const { ageGroupRestriction } = properties;
  switch (ageGroupRestriction) {
    case AGE_GROUP_RESTRICTION.UNDERSIXMONTHS:
      return renderUnderSixMonths(ageGroupRestriction);
    case AGE_GROUP_RESTRICTION.SIXMONTHSTOTWOYEARS:
      return renderSixMonthsToTwoYears(ageGroupRestriction);
    case AGE_GROUP_RESTRICTION.TWOYEARSTOTENYEARS:
      return renderTwoYearsToTenYears(ageGroupRestriction);
    case AGE_GROUP_RESTRICTION.ELEVENYEARSTOFIFTEENYEARS:
      return renderElevenYearsToFifteenYears(ageGroupRestriction);
    case AGE_GROUP_RESTRICTION.SIXTEENYEARSTOSEVENTEENYEARS:
      return renderSixteenYearsToSeventeenYears(ageGroupRestriction);
    case AGE_GROUP_RESTRICTION.EIGHTEENYEARSTOFORTYNINEYEARS:
      return renderEighteenYearsToFortyNineYears(ageGroupRestriction);
    case AGE_GROUP_RESTRICTION.FIFTYYEARSTOSIXTYFOURYEARS:
      return renderFiftyYearsToSixtyFourYears(ageGroupRestriction);
    case AGE_GROUP_RESTRICTION.OVERSIXTYFIVEYEARS:
      return renderNhsFreeEligible(ageGroupRestriction);
    default:
      return null;
  }
};

FluAgeGroupRestriction.propTypes = {
  properties: PropTypes.shape({
    ageGroupRestriction: PropTypes.string,
    mayBeEligibleForNhsFluVaccination: PropTypes.bool,
    eligibleForNhsFluVaccination: PropTypes.bool,
    notSuitableForNhsFluVaccination: PropTypes.bool,
    nhsFreeEligible: PropTypes.bool,
  }).isRequired,
  submissionId: PropTypes.string,
};

export default FluAgeGroupRestriction;
