import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Markup } from '@patient-access/ui-kit';

import MarkupList from './Elements/MarkupList';
import OutcomeWrapper from './Elements/OutcomeWrapper';
import OutcomeTitle from './Elements/OutcomeTitle';
import OutcomeBody from './Elements/OutcomeBody';
import OutcomeButton from './Elements/OutcomeButton';
import OutcomeButtonHeader from './Elements/OutcomeButtonHeader';
import {
  MEDICAL_CONDITIONS,
  ELEVEN_TO_SEVENTEEN_ELIGIBILITY_CONDITIONS,
} from '../../../constant';
import { redirectUrl } from '../../../sideEffects/urlMapper';

const TwelveYearsToSeventeenYears = ({ submissionId, properties, children }) => {
  const renderEligibleOrClinicallyExcluded = () => (
    <OutcomeWrapper>
      <OutcomeTitle id="cds.result.header.twelveYearsToSeventeenYears" defaultMessage="Seek GP practice advice" />
      <OutcomeBody>
        <FormattedMessage id="cds.result.content.twelveYearsToSeventeenYears"
          defaultMessage="Something went wrong. Please try again later."
          values={{
            br: <br />,
            medicalConditionsList: <MarkupList list={MEDICAL_CONDITIONS} />,
            elevenToSeventeenList: <MarkupList list={ELEVEN_TO_SEVENTEEN_ELIGIBILITY_CONDITIONS} />,
          }}
        />
        <br /> <br />
        {children}
      </OutcomeBody>
      <div className="cds-buttons" >
        <OutcomeButton type="primary" messageKey="cds.result.button.go.home" link={window.__ENV.padotcomURL} />
      </div>
    </OutcomeWrapper>
  );

  const renderNotEligible = (subject) => {
    const registerUrl = redirectUrl('registration/create', submissionId, false);
    const loginUrl = redirectUrl('login', submissionId, false);
    const eligibilityConditions = (
      <Markup>
        <ul>
          <li>
            <FormattedMessage id={`cds.result.content.twelveYearsToSeventeenYears.noteligible.carer.${subject}`}
              defaultMessage="They are over 16 and a carer receiving carer\'s allowance." />
          </li>
          <li>
            <FormattedMessage id={`cds.result.content.twelveYearsToSeventeenYears.noteligible.bmi.${subject}`}
              defaultMessage="They are severely obese with a BMI (body mass index) over 40." />
          </li>
        </ul>
      </Markup>
    );

    return (
      <OutcomeWrapper>
        <OutcomeTitle id="cds.result.header.twelveYearsToSeventeenYears.noteligible" defaultMessage="Not Eligible for free NHS flu vaccination" />
        <OutcomeBody>
          <FormattedMessage id={`cds.result.content.noteligible.${subject}1`} defaultMessage="Sorry something went wrong." />
          <br />
          <FormattedMessage id="cds.result.content.twelveYearsToSeventeenYears.noteligible"
            defaultMessage=""
            values={{
              br: <br />,
              elevenToSeventeenList: eligibilityConditions,
            }}
          />
          <FormattedMessage id={`cds.result.content.twelveYearsToSeventeenYears.noteligible.${subject}1`} defaultMessage="" />
          <br /><br />
          <FormattedMessage id={`cds.result.content.noteligible.${subject}3`} defaultMessage="" />
          <br /><br />
          <FormattedMessage id={`cds.result.content.twelveYearsToSeventeenYears.noteligible.${subject}2`} defaultMessage="" />
          <br /> <br />
          {children}
        </OutcomeBody>
        <OutcomeButtonHeader headerId="cds.result.button.header1">
          <OutcomeButton type="primary" messageKey="cds.result.button.register.book" link={registerUrl} />
        </OutcomeButtonHeader>

        <OutcomeButtonHeader headerId="cds.result.button.header2">
          <OutcomeButton type="outline" messageKey="cds.result.button.signin.book" link={loginUrl} />
        </OutcomeButtonHeader>
      </OutcomeWrapper>
    );
  };

  const { clinicallyExcluded, nhsFreeEligible, subject } = properties;
  if (nhsFreeEligible || clinicallyExcluded) {
    return renderEligibleOrClinicallyExcluded();
  }

  return renderNotEligible(subject);
};

TwelveYearsToSeventeenYears.propTypes = {
  submissionId: PropTypes.string,
  properties: PropTypes.shape({
    ageGroupRestriction: PropTypes.string,
    clinicallyExcluded: PropTypes.bool,
    nhsFreeEligible: PropTypes.bool,
    subject: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
};

export default TwelveYearsToSeventeenYears;