import actionTypes from './actionTypes';
import { getNextPage } from './utils/formNavigation';
import setFormValue from './utils/formSetValue';
import validate from './validation';
import { isPageValid } from './selectors';

export const initialState = {
    triage: {
        formData: {
            pages: [],
            currentPageIndex: -1,
        },
        userData: {
            pages: [],
            currentPageIndex: 0,
        },
        overlay: {
            content: '',
            visible: false,
            isRedFlag: false,
        },
        status: {
            isSynchronising: false,
            lastError: null,
        },
    },
    cds: {

    }
};

const handleNavigateForward = (state, payload) => {
    if (payload.pageIndex === 0) {
        return getNextPage(state, payload.pageIndex + 1);
    }
    if (isPageValid(state)) {
        return getNextPage(state, payload.pageIndex + 1);
    }
    return state;
}

const fetchFormSuccess = (state, payload) => {
    return Object.assign({}, state, {
        triage: {
            ...state.triage,
            formData: {
                currentPageIndex: initialState.triage.formData.currentPageIndex,
                ...payload.formData,
            },
        }
    });
}

const handleValidatePage = (state, payload) => {
    return Object.assign({}, state, {
        triage: {
            ...state.triage,
            userData: {
                ...state.triage.userData,
                pages: [
                    ...state.triage.userData.pages.map((page, index) => {
                        if (payload.pageIndex !== index) {
                            return page;
                        }

                        return {
                            ...page,
                            fields: [
                                ...page.fields.map((field) => {
                                    if (field.readOnly) {
                                        return {
                                            ...field,
                                            error: null,
                                        };
                                    }

                                    return {
                                        ...field,
                                        error: validate(field),
                                    };
                                }),
                            ],
                        };
                    }),
                ],
            },
        },
    });
}

const handleCloseOverlay = (state) => {
    return Object.assign({}, state, {
        triage: {
            ...state.triage,
            overlay: {
                ...initialState.triage.overlay,
            },
        }
    });
}

const handleResetForm = state => Object.assign({}, state, {
    triage: {
        ...initialState.triage,
        formData: {
            ...initialState.triage.formData,
            pages: state.triage.formData.pages,
        },
    },
});

const handleIsSynchronising = (state, payload) => Object.assign({}, state, {
    triage: {
        ...state.triage,
        status: {
            ...state.triage.status,
            isSynchronising: payload.isSynchronising,
        }
    }
});

export const reducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.FETCH_FORM_SUCCESS:
            return fetchFormSuccess(state, payload);
        case actionTypes.NAVIGATE_FORWARD:
            return handleNavigateForward(state, payload);
        case actionTypes.SET_FORM_VALUE:
            return setFormValue(state, payload);
        case actionTypes.VALIDATE_PAGE:
            return handleValidatePage(state, payload);
        case actionTypes.CLOSE_OVERLAY:
            return handleCloseOverlay(state);
        case actionTypes.RESET_FORM:
            return handleResetForm(state);
        case actionTypes.IS_SYNCHRONISING:
            return handleIsSynchronising(state, payload);
        default:
            return state;
    }
};