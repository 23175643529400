import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import OutcomeWrapper from './Elements/OutcomeWrapper';
import OutcomeTitle from './Elements/OutcomeTitle';
import OutcomeBody from './Elements/OutcomeBody';
import OutcomeButton from './Elements/OutcomeButton';
import TwelveYearsToSeventeenYears from './TwelveYearsToSeventeenYears';
import {
  AGE_GROUP_RESTRICTION,
} from '../../../constant';

const AgeGroupRestriction = ({ submissionId, properties, children }) => {
  const renderTwelveYearsToSeventeenYears = () => (
    <TwelveYearsToSeventeenYears submissionId={submissionId} properties={properties} >
      {children}
    </TwelveYearsToSeventeenYears>
  );

  const renderUnderSixMonths = () => (
    <OutcomeWrapper>
      <OutcomeTitle id={`cds.result.header.underSixMonths`} defaultMessage="" />
      <OutcomeBody>
        <FormattedMessage id={`cds.result.content.underSixMonths`} defaultMessage="Something went wrong. Please try again later." />
      </OutcomeBody>
      <div className="cds-buttons" >
        <OutcomeButton type="primary" messageKey="cds.result.button.go.home" link={window.__ENV.padotcomURL} />
      </div>
    </OutcomeWrapper>
  );

  const renderOtherAgeGroupContents = (ageGroupRestriction) => (
    <OutcomeWrapper>
      <OutcomeTitle id={`cds.result.header.${ageGroupRestriction}`} defaultMessage="" />
      <OutcomeBody>
        <FormattedMessage id={`cds.result.content.${ageGroupRestriction}`} values={{ br: <br /> }} defaultMessage="Something went wrong. Please try again later." />
        <br /> <br />
        {children}
      </OutcomeBody>
      <div className="cds-buttons" >
        <OutcomeButton type="primary" messageKey="cds.result.button.go.home" link={window.__ENV.padotcomURL} />
      </div>
    </OutcomeWrapper>
  );

  const { ageGroupRestriction } = properties;
  switch (ageGroupRestriction) {
    case AGE_GROUP_RESTRICTION.UNDERSIXMONTHS:
      return renderUnderSixMonths();
    case AGE_GROUP_RESTRICTION.TWELVEYEARSTOSEVENTEENYEARS:
      return renderTwelveYearsToSeventeenYears();
    case AGE_GROUP_RESTRICTION.TWOYEARSTOELEVENYEARS:
    case AGE_GROUP_RESTRICTION.SIXMONTHSTOTWOYEARS:
    default:
      return renderOtherAgeGroupContents(ageGroupRestriction);
  }
};

AgeGroupRestriction.propTypes = {
  submissionId: PropTypes.string,
  properties: PropTypes.shape({
    ageGroupRestriction: PropTypes.string,
    clinicallyExcluded: PropTypes.bool,
    nhsFreeEligible: PropTypes.bool,
    subject: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
};

export default AgeGroupRestriction;