import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStateValue } from '../../context';
import { getAsthmaFormData } from '../../data';
import { Loader } from '@patient-access/ui-kit';
import TriageForm from '../../components/Triage/TriageForm';
import { doSubmitForm, doStartForm } from '../../context/dispatcher';
import Button from '../../components/Triage/TriageOverlayButton';
import { postMessageToParent } from '../../components/Common/Helpers';
import CoverPageView from '../../components/Common/CoverPageView';
import { WIDGET_TYPE } from '../../constant';

const AsthmaFormView = ({ location }) => {
    const formName = 'asthmaExa';
    const [inputFormData, setInputFormData] = useState(null);
    const [buttonStatus, setbuttonStatus] = useState(false);
    const [state, dispatch] = useStateValue();
    const { userData, status } = state.triage || {};
    const { isLastPage } = userData || {};
    const hasOutcomePage = true;

    useEffect(() => {
        const data = getAsthmaFormData();
        setInputFormData(data);
    }, []);

    const submissionHandler = async () => {
        const submissionResult = await doSubmitForm(location, formName)(state, dispatch);
        if (submissionResult && submissionResult.submissionId) {
            const messageToParentApp = Object.assign({}, submissionResult);
            postMessageToParent(messageToParentApp);
        }
        doStartForm()(dispatch);
    };

    const onContinueClick = (e) => {
        e.preventDefault();
        setbuttonStatus(true);
    }

    const renderCoverPage = () => {
        return (
            <div className="cds-wrapper cds-wrapper-medium">
                <CoverPageView
                    onContinueClick={onContinueClick}
                    widgetType={WIDGET_TYPE.ASTHMA_EXA}
                    referenceInfo={inputFormData && inputFormData.referenceInfo}
                />
                {inputFormData && inputFormData.referenceInfo && <p className="cds-footer">{inputFormData.referenceInfo}</p>}
            </div>
        );
    }

    const renderOverlayButtons = () => (
        <Button buttonType="primary"
            messageKey="cds.triage.button.exit"
            onClick={submissionHandler} />
    );

    if (!inputFormData || (status && status.isSynchronising)) {
        return <Loader type="block" size="medium" className="cds-loader" />
    }

    if (buttonStatus) {
        return (
            <>
                {inputFormData && inputFormData.referenceInfo && <p className="cds-footer">{inputFormData.referenceInfo}</p>}
                <TriageForm inputFormData={inputFormData} hasOutcomePage={hasOutcomePage} isLastPage={isLastPage} submissionHandler={submissionHandler}>
                    {renderOverlayButtons()}
                </TriageForm>
            </>
        );
    }

    return renderCoverPage();
}

AsthmaFormView.propTypes = {
    location: PropTypes.any,
};

export default AsthmaFormView;