import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@patient-access/ui-kit';

const TriageOverlayButton = ({ messageKey, buttonType, onClick }) => {
    return (
        <Button
            className="cds-button-content"
            buttonLength="half"
            buttonSize="medium"
            defaultMessage="Continue"
            messageKey={messageKey}
            buttonType={buttonType}
            onClick={(e) => {
                e.preventDefault();
                onClick();
            }}
        />
    );
};

TriageOverlayButton.propTypes = {
    messageKey: PropTypes.string,
    buttonType: PropTypes.string,
    continueClickHandler: PropTypes.func,
};
export default TriageOverlayButton;