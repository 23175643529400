import cloneDeep from 'lodash.clonedeep';
import isEmpty from 'lodash.isempty';
import validate from '../validation';
import { removePages } from './formNavigation';

const setAlertContent = (state, alertInfo) => {
  const newState = state;
  newState.triage.overlay.content = alertInfo.content;
  newState.triage.overlay.visible = true;
  newState.triage.overlay.isRedFlag = alertInfo.stop;
};

const getAlerts = (state, field, value) => {
  Object.entries(field.alerts).map((entry) => {
    const alertId = entry[0];
    const alertInfo = entry[1];
    if (alertId === value) {
      return setAlertContent(state, alertInfo);
    }
    return state;
  });
};

const setMultipleValue = (field, choiceId, isSelected) => {
  const fieldObject = field;
  fieldObject.options.choices[choiceId].isSelected = isSelected;
  const hasSelectedChoices = Object.values(field.options.choices).filter(choice => choice.isSelected === true).length > 0;
  fieldObject.value = hasSelectedChoices ? 'Has been selected' : '';
};

const setFormValue = (state, payload) => {
  const newState = cloneDeep(state);
  const { fields, formDataIndex } = newState.triage.userData.pages[payload.pageId];
  const field = fields.find(item => (item.id === payload.fieldId && !item.readOnly));
  let valueForAlert;

  if (payload.choiceId) {
    setMultipleValue(field, payload.choiceId, payload.value);
    valueForAlert = payload.choiceId;
  } else {
    field.value = payload.value;
    valueForAlert = payload.value;
  }

  if (!isEmpty(field.alerts) && payload.value) {
    getAlerts(newState, field, valueForAlert);
  }

  field.error = validate(field);

  // clear the next questions
  return removePages(newState, payload.pageId + 1, formDataIndex);
};

export default setFormValue;

