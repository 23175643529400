const getCDSBaseUrl = () => {
    return window && window.__ENV && window.__ENV.cdsApiBaseUrl;
}

const getWidgetVersion = () => {
    return window && window.__ENV && window.__ENV.widgetVersion;
}

const getQuestionnaireId = (formName) => {
    return window && window.__ENV && window.__ENV.questionnaireId && window.__ENV.questionnaireId[formName];
}

export {
    getCDSBaseUrl,
    getWidgetVersion,
    getQuestionnaireId,
}