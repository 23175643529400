import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Form, Loader } from '@patient-access/ui-kit';

import renderTriagePages from './TriagePages';
import TriageOverlay from './TriageOverlay';
import { FormHeader, ScrollForm } from '../Common';
import { useStateValue } from '../../context';
import {
  doLoadForm,
  doStartForm,
  doHandleFormValueChange,
  doHandleContinueClick
} from '../../context/dispatcher';
import PageExitPrompt  from '../../components/Common/PageExitPrompt';
import SubmitWrapper from '../../components/Common/SubmitWrapper';

const TriageForm = ({ inputFormData, submissionHandler, isLastPage, hasOutcomePage, submissionOverlayContent, children}) => {
  const [state, dispatch] = useStateValue();
  const { formData, userData, overlay, status } = state.triage || {};
  const { isSynchronising, lastError } = status || {};
  const { pages } = userData || {};
  const [isContinueButtonClicked, clicked] = useState(false);

  useEffect(() => {
    if (inputFormData) {
      doLoadForm(inputFormData)(dispatch);
      doStartForm()(dispatch);
    }
  }, [dispatch, inputFormData]);

  const changeHandler = (value, fieldId, choiceId, pageId) => {
    doHandleFormValueChange(value, fieldId, choiceId, pageId)(dispatch);
    hasOutcomePage && clicked(false)
  };

  const continueClickHandler = pageId => {
    doHandleContinueClick(pageId)(dispatch);
    hasOutcomePage && clicked(true)
  };

  const renderErrorMessage = lastError => {
    if (lastError) {
      return (
        <div className="cds-error">
          <FormattedMessage id="cds.error" defaultMessage="Sorry unexpected error occured. Please try later." />
        </div>
      );
    }
  };

  const renderOverlay = (overlay, children) => {
    const { content, visible } = overlay || {};
    return (
      <TriageOverlay content={content} visible={visible}>
        {children}
      </TriageOverlay>
    );
  }

  if (isSynchronising || !(pages && pages.length)) {
    return <Loader type="block" size="medium" className="cds-loader" />
  }

  return (
    <>
      {/* <FormHeader header={formData && formData.description} /> */}
      <div className="cds-wrapper cds-wrapper-medium" aria-label="form">
        <PageExitPrompt>
          <Form>
            <ScrollForm selectorClass="page" activeClass="active">
              {renderTriagePages(pages, changeHandler, continueClickHandler)}
            </ScrollForm>
            {renderOverlay(overlay, children)}
            {renderErrorMessage(lastError)}
            {isLastPage && <SubmitWrapper canShowSubmitButton={hasOutcomePage && isContinueButtonClicked} submissionHandler={submissionHandler} submissionOverlayContent={submissionOverlayContent}/>}
          </Form>
        </PageExitPrompt>
      </div>
    </>
  );
}

TriageForm.propTypes = {
  inputFormData: PropTypes.object,
  children: PropTypes.node,
  isLastPage: PropTypes.bool,
  hasOutcomePage: PropTypes.bool,
  submissionHandler: PropTypes.any,
  submissionOverlayContent: PropTypes.string,
  isContinueButtonClicked: PropTypes.bool,
};
export default TriageForm;
