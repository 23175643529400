import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const OutcomeTitle = ({ id, defaultMessage }) => {
  return (
    <div className="cds-title-header">
      <h1>
        <FormattedMessage id={id} defaultMessage={defaultMessage} />
      </h1>
    </div>
  );
};

OutcomeTitle.propTypes = {
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string,
};

export default OutcomeTitle;