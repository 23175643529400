import React, { Component } from 'react';
import PropTypes from 'prop-types';

import getFormElement from '../Forms/FormElements/formElementFactory';
import { isWritableField } from '../Forms/Helpers';

class FormElements extends Component {
  constructor(props) {
    super(props);
    this.changeHandler = this.changeHandler.bind(this);
  }

  renderFormElements(fields) {
    return fields.filter(isWritableField).map(field => getFormElement(field, this.changeHandler, '', '', ''));
  }

  changeHandler(value, fieldId, choiceId) {
    const {
      changeHandler,
      formDataIndex,
    } = this.props;
    changeHandler(value, fieldId, choiceId, formDataIndex);
  }

  render() {
    const {
      fields,
    } = this.props;
    return this.renderFormElements(fields);
  }
}

FormElements.propTypes = {
  fields: PropTypes.array.isRequired,
  changeHandler: PropTypes.func.isRequired,
  formDataIndex: PropTypes.number,
};

export default FormElements;
