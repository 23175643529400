import React, { Component } from 'react';
import PropTypes from 'prop-types';
import zenscroll from 'zenscroll';
import { isIOS } from '@patient-access/utils';

class ScrollForm extends Component {
  constructor(props) {
    super(props);
    this.refList = [];
    this.clickOnChild = this.clickOnChild.bind(this);
    this.getElementsInArea = this.getElementsInArea.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollToChildByIndex = this.scrollToChildByIndex.bind(this);
    
    zenscroll.setup();
    this.rootDiv = document.getElementById("root");
    this.rootScroller = zenscroll.createScroller(this.rootDiv, 500, 20);
    this.scroller = isIOS() ? this.rootScroller : zenscroll;
  }

  get getValidChilren() {
    return this.props.children && this.props.children.filter(child => child !== null);
  }

  get getlastChildIndex() {
    return this.getValidChilren && (this.getValidChilren.length - 1);
  }

  // Disable the form elements for the inactive questions
  disableFormFieldsInStep(step, value) {
    var fields = step.querySelectorAll('input, button');
    if (fields) {
      for (let j = fields.length; j--;) {
        let field = fields[j];
        field.disabled = value;
      }
    }
  }

  // Check if element is in center of the viewport
  // and apply/remove active class
  getElementsInArea = (e, opts) => {
    let docElm = document.documentElement;
    let viewportHeight = docElm.clientHeight;

    if (e && e.type === 'resize') {
      viewportHeight = docElm.clientHeight;
    }

    let activeElementSet = false;
    for (let i = opts.elements.length; i--;) {
      let elm = opts.elements[i];
      const pos = elm.getBoundingClientRect();
      const topPerc = pos.top / viewportHeight * 100;
      const bottomPerc = pos.bottom / viewportHeight * 100;
      const middle = (topPerc + bottomPerc) / 2;

      //check if the middle of the element is in the zone
      let inViewport = middle > opts.zone[1] &&
        middle < (100 - opts.zone[1]);

      // If element is lengthier than the viewport
      if ((elm.offsetHeight / viewportHeight) * 100 > 80) {
        inViewport = (topPerc < 60 && bottomPerc > 50);
      }

      if (inViewport && !activeElementSet) {
        elm.classList.add(opts.markedClass);
        this.disableFormFieldsInStep(elm, false);
        activeElementSet = true;
      }
      else {
        elm.classList.remove(opts.markedClass);
        this.disableFormFieldsInStep(elm, true);
      }
    }

    // if no element is set to active
    if (!activeElementSet) {
      let elm = opts.elements && opts.elements[0];
      if (elm) {
        elm.classList.add(opts.markedClass);
        this.disableFormFieldsInStep(elm, false);
        activeElementSet = true;
      }
    }
  }

  handleScroll = (e) => {
    const { selectorClass, activeClass } = this.props;

    this.getElementsInArea(e, {
      elements: document.querySelectorAll('.' + selectorClass),
      markedClass: activeClass,
      zone: [30, 30] // percentage distance from top & bottom
    });
  }

  scrollToChildByIndex(index) {
    let docElm = document.documentElement;
    let viewportHeight = docElm.clientHeight - 200;
    const duration = 500 // milliseconds
    const child = this.refList[index];
    const stickyHeaderHeight = 90;
    const marginFromHeader = 24;
    if (child) {
      const formItem = child.current
      if (formItem) {
        const top = this.scroller.getTopOf(formItem);
        if (formItem.offsetHeight > viewportHeight) {
          this.scroller.toY(top - (stickyHeaderHeight + marginFromHeader), duration);
        } else {
          this.scroller.center(formItem, duration);
        }
      }
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
    window.addEventListener('resize', this.handleScroll, true);
    this.scrollToChildByIndex(this.getlastChildIndex);
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
    window.removeEventListener('resize', this.handleScroll, true);
  }

  //Check if the question is active
  isElementActive(element) {
    const { selectorClass, activeClass } = this.props;

    const formItem = element && element.querySelector('.' + selectorClass);
    const isActiveItem = formItem && formItem.classList.contains(activeClass);

    return isActiveItem;
  }

  //Scroll to the latest rendered question if not already
  componentDidUpdate() {
    const lastChild = this.refList[this.getlastChildIndex];
    const formItem = lastChild && lastChild.current;
    const isActiveItem = this.isElementActive(formItem);
    if (!isActiveItem) {
      this.scrollToChildByIndex(this.getlastChildIndex);
    }
  }

  //Scroll to the clicked question if it is inactive
  clickOnChild = (e) => {
    const childIndex = +(e && e.currentTarget && e.currentTarget.getAttribute('name'));
    const formItem = e && e.currentTarget;
    const isActiveItem = this.isElementActive(formItem);
    if (!isActiveItem) {
      this.scrollToChildByIndex(childIndex);
    }
  }

  setChild = (element, index) => {
    if (!element) { return null; }
    this.refList[index] = React.createRef(index);
    const lastChildClass = this.getlastChildIndex === index ? 'current-child' : 'answered-child';
    const firstChildClass = index ? '' : 'first-child';
    return (
      <div onClick={this.clickOnChild}
        ref={this.refList[index]}
        name={index}
        className={`${lastChildClass} ${firstChildClass}`}>
        {element}
      </div>
    );
  }

  getCurrentView = () => {
    let allChildren = [];
    if (!this.props.children) return null;
    allChildren = React.Children.map(this.getValidChilren, (child, index) => {
      let currentChild = null;
      if (index <= this.getlastChildIndex) {
        currentChild = this.setChild(child, index);
      }
      return currentChild;
    });

    return allChildren;
  }

  render() {
    return (
      <div className="form-container">
        {this.getCurrentView()}
      </div>
    );
  }
}

ScrollForm.propTypes = {
  children: PropTypes.array,
  selectorClass: PropTypes.string.isRequired,
  activeClass: PropTypes.string.isRequired,
  currentStep: PropTypes.number,
};

export default ScrollForm;
