// import { parsePhoneNumber } from 'libphonenumber-js';
import regExList from '@patient-access/utils/lib/regExList';
import emptyValidator from './emptyValidator';
import defaultValidator from './defaultValidator';
import textValidator from './textValidator';

const getValidator = (errorType) => {
  switch (errorType) {
    case 'IS_BLANK_ERROR':
      return emptyValidator;
    case 'TYPE_INVALID':
      return textValidator;
    default:
      return defaultValidator;
  }
};

const validate = (field) => {
  const { value, options: { constraints }, type: fieldType, validationType } = field;
  /*eslint-disable no-unused-vars*/
  for (const errorType in constraints) {
    if (Object.prototype.hasOwnProperty.call(constraints, errorType)) {
      const message = (fieldType === 'Date') ? 'Please enter valid date' : constraints[errorType];
      const error = getValidator(errorType)(value, message);
      if (error) return error;
    }
  }
  // const regionCode = 'GB';
  let textError = null;

  if (!value) {
    return null;
  }

  switch (validationType) {
    case 'Email':
      if (!value.match(regExList.email)) {
        textError = getValidator('TYPE_INVALID')(value, 'Please enter valid Email');
      }
      break;
    case 'Tel':
      if (value.length < 10 || !value.match(regExList.phone)) {
        textError = getValidator('TYPE_INVALID')(value, 'Please enter valid Phone Number');
        break;
      }
      // if (!parsePhoneNumber(value, regionCode).isValid()) {
      //   textError = getValidator('TYPE_INVALID')(value, 'Please enter valid Phone Number');
      // }
      break;
    default:
      textError = null;
      break;
  }
  return textError;
};

export default validate;
