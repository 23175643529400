import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const OutcomeButtonHeader = ({headerId, children}) => {
  return (
    <Fragment>
      <div className="cds-resultview-sub-header">
        <h3><FormattedMessage id={headerId} defaultMessage="New to Patient Access?" /></h3>
      </div>
      <div className={`cds-resultview-button${children.props.messageKey.includes("book") ? "-book" : ""}`}>
        {children}
      </div>
    </Fragment>
  );
};

OutcomeButtonHeader.propTypes = {
  headerId: PropTypes.string,
  children: PropTypes.node,
};

export default OutcomeButtonHeader;