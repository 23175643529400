import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FormItem, Checkbox as UiCheckbox, Button, Radio as UiRadio } from '@patient-access/ui-kit';

import FieldLabel from './FieldLabel';
import { NONEOFTHEABOVE } from '../../../constant';
import HeaderElement from './HeaderElement';
import ContentElement from './ContentElement';

class MultiChoiceWithContinue extends Component {
  constructor(props) {
    super(props);
    this.CheckboxchangeHandler = this.CheckboxchangeHandler.bind(this);
    this.state = {
      checkedValue: this.props.selectedValue.value === '' ? [] : this.props.selectedValue,
    }
  }
  CheckboxchangeHandler(value, checked) {
    if (checked && value === NONEOFTHEABOVE) {
      this.setState({
        checkedValue: [NONEOFTHEABOVE]
      });
    }
    else if (checked) {
      let checkedvalue = this.state.checkedValue;
      checkedvalue = checkedvalue[0] === NONEOFTHEABOVE ? [] : checkedvalue;
      this.setState({
        checkedValue: this.state.checkedValue !== [] ?
          checkedvalue.concat(value) : value
      });
    } else {
      let checkedvalue = this.state.checkedValue;
      checkedvalue = checkedvalue.filter(data => data !== value);
      this.setState({
        checkedValue: checkedvalue
      });
    }
  }

  isSelected(value) {
    const checkValue = this.state.checkedValue;

    if (checkValue.indexOf(value) !== -1) {
      return true;
    }
    else {
      return false;
    }
  }
  renderContinueButton() {
    const { field } = this.props;
    return (<Button
      className="cds-buttons"
      id="Continue"
      messageKey="cds.button.continue"
      buttonType="primary"
      buttonLength="half"
      buttonSize="medium"
      defaultMessage="Continue"
      isDisabled={this.state.checkedValue.length === 0}
      onClick={(e) => {
        e.preventDefault();
        this.props.changeHandler(this.state.checkedValue, field.id, this.state.checkedValue);
      }}
    />);
  }

  render() {
    const { field, clearNextQuestionsWhileContinue, fieldNumber } = this.props;
    return (
      <FormItem type="item" dataId={`form-checkbox-${fieldNumber}`}>
        <HeaderElement>
          <FieldLabel field={field} fieldNumber={fieldNumber} faux />
        </HeaderElement>
        <ContentElement>
          {Object.entries(field.choices[0]).map(choice => choice[0] !== NONEOFTHEABOVE ? (
            <UiCheckbox
              className={this.isSelected(choice[0]) ? 'input-checkbox-checked' : ''}
              key={choice[0]}
              id={`${field.id}_${choice[0]}`}
              name={`${field.id}_${choice[0]}`}
              value={choice[0] || ''}
              onChange={event => {
                this.CheckboxchangeHandler(choice[0], event.target.checked);
                clearNextQuestionsWhileContinue(field.id);
              }}
              checked={this.isSelected(choice[0])}
              label={choice[1]}
              type="card"
            />
          ) : (
            <div className="cds-step-noneoftheabove" key={choice[0]}>
              <UiRadio
                className={this.isSelected(choice[0]) ? 'input-radio-checked' : ''}
                id={`${field.id}_${choice[0]}`}
                name={`${field.id}_${choice[0]}`}
                value={choice[0] || ''}
                onChange={event => {
                  this.CheckboxchangeHandler(choice[0], event.target.checked);
                  clearNextQuestionsWhileContinue(field.id);}}
                checked={this.isSelected(choice[0])}
                label={choice[1]}
                type="card"
              />
            </div>
            )
          )}
      </ContentElement>
      {field.nextQuesMapper !== '' ? this.renderContinueButton() :
            (this.props.changeHandler(this.state.checkedValue, field.id, this.state.checkedValue),
            this.props.renderSubmitButton(this.state.checkedValue.length))}
      </FormItem>
    );
  }
}

MultiChoiceWithContinue.propTypes = {
  field: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    options: PropTypes.shape({
      label: PropTypes.string,
      choices: PropTypes.object,
    }),
    error: PropTypes.string,
  }).isRequired,
  changeHandler: PropTypes.func.isRequired,
  clearNextQuestionsWhileContinue: PropTypes.func,
  renderSubmitButton: PropTypes.func,
  selectedValue: PropTypes.object,
};

export default MultiChoiceWithContinue;
