import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@patient-access/ui-kit';

const TriageContinueButton = ({ index, continueClickHandler }) => {
    return (
        <div className="cds-step-button">
            <Button
                className="cds-buttons"
                id="Continue"
                messageKey="cds.button.continue"
                buttonType="primary"
                buttonLength="half"
                buttonSize="medium"
                defaultMessage="Continue"
                onClick={(e) => {
                    e.preventDefault();
                    continueClickHandler(index);
                }}
            />
        </div>
    );
};

TriageContinueButton.propTypes = {
    index: PropTypes.number,
    continueClickHandler: PropTypes.func,
};
export default TriageContinueButton;