import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FormItem, Checkbox as UiCheckbox } from '@patient-access/ui-kit';

import FieldLabel from './FieldLabel';
import { renderValidationMessage } from '../Helpers/ui';
import HeaderElement from './HeaderElement';
import ContentElement from './ContentElement';

class Checkbox extends Component {
  render() {
    const { field, changeHandler, fieldNumber } = this.props;
    return (
      <FormItem type="item" error={field.error} dataId={`form-checkbox-${field.id}`}>
        <HeaderElement>
        <FieldLabel field={field} fieldNumber={fieldNumber} faux />
        </HeaderElement>
        <ContentElement>
        {Object.entries(field.options.choices).map(choice => (
          <UiCheckbox
            className={choice[1].isSelected ? 'input-checkbox-checked' : ''}
            key={choice[0]}
            id={`${field.id}_${choice[0]}`}
            name={`${field.id}_${choice[0]}`}
            value={choice[0] || ''}
            onChange={event => changeHandler(event.target.checked, field.id, choice[0])}
            checked={choice[1].isSelected ? choice[1].isSelected : false}
            label={choice[1].displayValue}
            type='card'
          />
        ))}
        </ContentElement>
        {renderValidationMessage(field.error)}
      </FormItem>
    );
  }
}

Checkbox.propTypes = {
  field: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    options: PropTypes.shape({
      label: PropTypes.string,
      choices: PropTypes.object,
    }),
    error: PropTypes.string,
  }).isRequired,
  changeHandler: PropTypes.func.isRequired,
};

export default Checkbox;
