import axios from 'axios';
import HttpErrors from './httpConstants';
import { getCDSBaseUrl } from '../components/Common/Helpers/envService';

const defaultOptions = {
  validateStatus: status => (status >= 200 && status < 500),
};

async function unauthenticatedPost(url, body) {
  let response;
  try {
    response = await axios.post(url, body, {
      headers: {
        'Content-Type': 'application/json',
      },
      ...defaultOptions,
    });
  } catch (error) {
    throw new Error(HttpErrors.CONNECTION_ERROR);
  }

  return checkResponseCodes(response);
}

async function unauthenticatedGet(url) {
  let response;
  try {
    response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
      ...defaultOptions,
    });
  } catch (error) {
    throw new Error(HttpErrors.CONNECTION_ERROR);
  }

  return checkResponseCodes(response);
}

const badRequestMessage = (responseBody) => {
  if (responseBody.message) {
    return responseBody.message;
  }
  return HttpErrors.BAD_REQUEST;
};

function checkResponseCodes(response) {
  switch (response.status) {
    case 200:
    case 201:
    case 202:
    case 204:
      return response.data;
    case 400:
      throw new Error(badRequestMessage(response.data));
    case 401:
      throw new Error(HttpErrors.UNAUTHORIZED);
    case 403:
      throw new Error(HttpErrors.FORBIDDEN);
    case 404:
      throw new Error(HttpErrors.NOT_FOUND);
    case 409:
      throw new Error(HttpErrors.CONFLICT);
    case 422:
      throw new Error(HttpErrors.UNPROCESSABLE_ENTITY);
    case 500:
      throw new Error(HttpErrors.SERVER_ERROR);
    default:
      throw new Error(HttpErrors.UNKNOWN);
  }
}

const api = uri => `${getCDSBaseUrl()}${uri}`;

export {
  unauthenticatedPost,
  unauthenticatedGet,
  api,
};