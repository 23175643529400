import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const FieldLabel = ({ field, faux, fieldNumber }) => {
  const renderFieldNumber = () => {
    if (fieldNumber) {
      return (<span className="cds-step-number">{`${fieldNumber}.`}</span>);
    }
    return null;
  }

  if (faux) {
    if (field.options && field.options.label) {
      return (
        <h1 tabIndex={0} role="text">
          {renderFieldNumber(fieldNumber)}
          <span>{`${field.options.label}`}</span>
        </h1>
      );
    }
    return (
      <Fragment>
        <h1 tabIndex={0} role="text">
          {renderFieldNumber(fieldNumber)}
          <span>{`${field.question}`}</span>
        </h1>
      </Fragment>
    );
  }
  return (
    <h1 tabIndex={0} role="text">
      <span>{field.question}</span>
    </h1>
  );
};

FieldLabel.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    options: PropTypes.shape({
      label: PropTypes.string,
      choices: PropTypes.object,
    }),
  }).isRequired,
  faux: PropTypes.bool,
  fieldNumber: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

export default FieldLabel;
