import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FormItem, Markup } from '@patient-access/ui-kit';

class Paragraph extends Component {
  render() {
    const { field } = this.props;
    return (
      <FormItem type="paragraph" dataId={`form-paragraph-${field.id}`}>
        <Markup>
          <div dangerouslySetInnerHTML={{ __html: field.options.data }} />
        </Markup>
      </FormItem>
    );
  }
}

Paragraph.propTypes = {
  field: PropTypes.shape({
    options: PropTypes.shape({
      data: PropTypes.node,
    }),
  }).isRequired,
};

export default Paragraph;
