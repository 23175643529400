import React from 'react';

import * as formElementTypes from './formElementTypes';
import { isInputField } from '../Helpers';

const getFormElement = (field, changeHandler, selectedValue, clearNextQuestionsWhileContinue, renderSubmitButton, fieldNumber) => {
  if (formElementTypes[field.type]) {
    const Element = formElementTypes[field.type];
    if (isInputField(field)) {
      return <Element key={field.id} field={field} changeHandler={changeHandler}
        selectedValue={selectedValue}
        clearNextQuestionsWhileContinue={clearNextQuestionsWhileContinue}
        renderSubmitButton={renderSubmitButton}
        fieldNumber={fieldNumber} />;
    }
    return <Element key={field.id} field={field} />;
  }
  return null;
};

export default getFormElement;
