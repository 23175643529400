const hasErrors = (page) => {
  if (!page || !page.fields) {
    return false;
  }

  return page.fields.filter(field => typeof field.error === 'undefined' || field.error !== null).length === 0;
};

export const getTriageForms = state => state.triage;

export const getUserFormPages = state => {
  const triageForms = getTriageForms(state);
  return triageForms.userData.pages;
};

export const getCurrentUserPageIndex = state => {
  const triageForms = getTriageForms(state);
  return triageForms.userData.currentPageIndex;
};

export const getUserFormPage = state => {
  const pages = getUserFormPages(state);
  const currentPageIndex = getCurrentUserPageIndex(state);

  if (currentPageIndex > pages.length - 1) {
    return pages[pages.length - 1];
  }
  return pages[currentPageIndex];
};

export const isPageValid = state => {
  const page = getUserFormPage(state);
  return hasErrors(page);
};

export const getOverlayContent = state => {
  const triageForms = getTriageForms(state);
  return triageForms.overlay.content;
}