
const redirectUrl = (pathvalue, submissionId, clinicallyExcluded) => {
  const { flu } = window.__ENV.questionnaireId || {};
  const returnurl = encodeURIComponent(`${window.__ENV.returnUrl}?submissionId=${submissionId}&widgettype=${flu}`);
  return `${window.__ENV.patientaccessURL}/${pathvalue}?clinicallyexcluded=${clinicallyExcluded}&returnUrl=${returnurl}`;
};

const privateFluInAppUrl = () => {
  return `${window.__ENV.patientaccessURL}/appointments/services/${window.__ENV.privateFluServiceId}/providers?serviceid=${window.__ENV.privateFluServiceId}&term=&type=defaultconfigpostcode&radius=5&radiusunit=miles`;
};

const privateFluPADotComUrl = () => {
  return `${window.__ENV.padotcomURL}/searchproviders?serviceId=${window.__ENV.privateFluServiceId}`;
};

export {
  redirectUrl,
  privateFluInAppUrl,
  privateFluPADotComUrl,
};