import { APPIDS } from  '../../../constant';

const isIOSNative = () => {
    return !!(window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.cdsMessage);
}

const isAndroidNative = () => {
    return !!(window.cdsMessage);
}

const postMessageToParent = (message) => {
    const messageString = JSON.stringify(message);

    // IOS in-app browser
    if (isIOSNative()) {
        window.webkit.messageHandlers.cdsMessage.postMessage(messageString);
    }
    // Android in-app browser
    else if (isAndroidNative()) {
        window.cdsMessage.postMessage(messageString);
    }
    // web browsers
    else if (window.parent) {
        window.parent.postMessage(messageString, '*');
    }
}

const isPatientAccessApp = (appId) => (APPIDS.PATIENTACCESS === appId);

const isInIframe = () => {
    try {
        return window.self !== window.top;
    }
    catch {
        return true;
    }
};

export {
    postMessageToParent,
    isIOSNative,
    isAndroidNative,
    isPatientAccessApp,
    isInIframe
}
