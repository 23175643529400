import React from 'react';
import isEmpty from 'lodash.isempty';

import { InputError } from '@patient-access/ui-kit';

const renderClearSelection = (field, changeHandler) => {
  if (!field.options.required && !isEmpty(field.value)) {
    // ToDo: fix the error - cannot load Link outside router
    // return (
    //   <InputClear
    //     message="Clear selection"
    //     onClick={() => changeHandler('', field.id)}
    //   />
    // );
    return null;
  }
  return null;
};

const renderValidationMessage = (error) => {
  if (error) {
    return (
      <div tabIndex={0} role="text">
        <InputError message={error} /></div>
    );
  }
  return null;
};

export {
  renderClearSelection,
  renderValidationMessage,
};
