import actionTypes from './actionTypes';

const fetchFormSuccess = formData => ({
  type: actionTypes.FETCH_FORM_SUCCESS,
  payload: {
    formData,
  },
});

const navigateForward = pageIndex => ({
  type: actionTypes.NAVIGATE_FORWARD,
  payload: {
    pageIndex,
  },
});

const setFormValue = (value, fieldId, pageId, choiceId) => {
  const payload = {
    value,
    fieldId,
    pageId,
  };
  if (choiceId) payload.choiceId = choiceId;
  return {
    type: actionTypes.SET_FORM_VALUE,
    payload,
  };
};

const closeOverlay = () => ({
  type: actionTypes.CLOSE_OVERLAY,
});

const validatePage = pageIndex => ({
  type: actionTypes.VALIDATE_PAGE,
  payload: {
    pageIndex,
  },
});

const isPageValid = () => ({
  type: actionTypes.IS_PAGE_VALID,
});

const resetForm = () => ({
  type: actionTypes.RESET_FORM,
});

const isSynchronising = isSynchronising => ({
  type: actionTypes.IS_SYNCHRONISING,
  payload: {
    isSynchronising,
  },
});

export {
  fetchFormSuccess,
  navigateForward,
  setFormValue,
  closeOverlay,
  validatePage,
  isPageValid,
  resetForm,
  isSynchronising,
};
