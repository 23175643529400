import React from 'react';
import { FormattedMessage } from 'react-intl';
import MediaQuery from 'react-responsive';
import { LinkExternal } from '@patient-access/ui-kit';
import { EXTERNAL_URL } from '../../constant';

const AsthmaCoverPageDescription = () => {
    const link = <LinkExternal to={EXTERNAL_URL.ASTHMA} target="_blank" message="www.giveyourlungsavoice.co.uk"
        defaultMessage="www.giveyourlungsavoice.co.uk" />;

    const description = () => {
        return (
            <div className="cds-logo-description">
                <p tabIndex={0} role="text">
                    <FormattedMessage id="cds.asthma.logo"
                        defaultMessage="Visit {link} for more patient related content. The website was created and funded by AstraZeneca. AstraZeneca has funded and provided input into the content of this questionnaire."
                        values={{ link }} /></p>
            </div>
        )
    }
    return (
        <div className="cds-body-content">
            <div className="cds-title-header">
                <h1 tabIndex={0} role="text"><FormattedMessage id="cds.asthma.header" defaultMessage="Consent to share and receive information" /></h1>
            </div>
            <div className="cds-content">
                <p tabIndex={0} role="text"><FormattedMessage id="cds.asthma.content" defaultMessage="As a user of the Patient Access Medication Assistant service we can provide you with information regarding the management of your asthma." /></p>
                <p tabIndex={0} role="text"><FormattedMessage id="cds.asthma.content1" defaultMessage="Please complete the following questionnaire. Your responses will help us to identify the relative severity of your asthma and give us an indication as to how it is currently being managed. This will help us to identify the information we believe to be most relevant to you and your condition. You've already provided your consent to the Patient Access Medication Assistant service but we thought it would be helpful to provide this additional information for clarity and transparency. You may withdraw your consent at any time if you no longer wish to use the service." /></p>
                <p tabIndex={0} role="text"><FormattedMessage id="cds.asthma.content2" defaultMessage="Please note that the articles and details we provide are not intended to be clinical advice or to supplement or replace the advice being provided by your healthcare professionals and are for information purposes only." /></p>
            </div>
            <MediaQuery maxWidth={767}>
                <div className="cds-logo"><img alt="timer" src={require('./../Logo/AZLogo.png')} /></div>
                {description()}
            </MediaQuery>
            <MediaQuery minWidth={768}>
                <div className="cds-logo-content" >
                    <div className="cds-logo"><img alt="timer" src={require('./../Logo/AZLogo.png')} /></div>
                    {description()}
                </div>
            </MediaQuery>
        </div>
    )
}

export default AsthmaCoverPageDescription;