import React from 'react';
import PropTypes from 'prop-types';
import { Markup } from '@patient-access/ui-kit';

const MarkupList = ({ list }) => (
  <Markup>
    <ul>
      {list && list.map((item, index) => <li key={index}>{item}</li>)}
    </ul>
  </Markup>
);

MarkupList.propTypes = {
  list: PropTypes.array.isRequired,
};

export default MarkupList;