import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FormItem } from '@patient-access/ui-kit';

class Header extends Component {
  render() {
    const { field } = this.props;
    return (
      <FormItem type="header" dataId={`form-header-${field.id}`}>
        <div dangerouslySetInnerHTML={{ __html: field.options.data }} />
      </FormItem>
    );
  }
}

Header.propTypes = {
  field: PropTypes.shape({
    options: PropTypes.shape({
      data: PropTypes.node,
    }),
  }).isRequired,
};

export default Header;
