import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FormItem, Textarea as UiTextarea } from '@patient-access/ui-kit';

import FieldLabel from './FieldLabel';
import { renderValidationMessage } from '../Helpers/ui';
import HeaderElement from './HeaderElement';
import ContentElement from './ContentElement';

class Textarea extends Component {
  render() {
    const { field, changeHandler } = this.props;
    return (
      <FormItem type="item" error={field.error} dataId={`form-textarea-${field.id}`}>
        <HeaderElement>
        <FieldLabel field={field} faux />
        </HeaderElement>
        <ContentElement>
        <UiTextarea
          id={field.id}
          name={field.id}
          rows={5}
          maxLength={500}
          onChange={event => changeHandler(event.target.value, field.id)}
          value={field.value ? field.value : ''}
          required={field.options.required}
          resize
        />
        </ContentElement>
        {renderValidationMessage(field.error)}
      </FormItem>
    );
  }
}

Textarea.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    options: PropTypes.shape({
      label: PropTypes.string,
      required: PropTypes.bool,
    }),
    value: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
  changeHandler: PropTypes.func.isRequired,
};

export default Textarea;
