import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FormItem, Radio as UiRadio } from '@patient-access/ui-kit';

import FieldLabel from './FieldLabel';
import HeaderElement from './HeaderElement';
import ContentElement from './ContentElement';

class SingleChoice extends Component {
  render() {
    const { field, changeHandler, selectedValue, fieldNumber } = this.props;
    return (
      <FormItem type="item" dataId={`form-radio-${fieldNumber}`}>
        <HeaderElement>
          <FieldLabel field={field} fieldNumber={fieldNumber} faux />
        </HeaderElement>
        <ContentElement>
          {Object.entries(field.choices[0]).map(choice => choice[0] !== 'skipped' ? (
            <UiRadio
              className={selectedValue.value === choice[0] ? 'input-radio-checked' : ''}
              key={choice[0]}
              id={`${field.id}_${choice[0]}`}
              name={field.id}
              value={choice[0]}
              onChange={event => changeHandler(event.target.value, field.id, choice[0])}
              checked={selectedValue.value === choice[0]}
              label={choice[1]}
              type="card"
            />
          ) : '')}

        </ContentElement>
        {(field.nextQuesMapper === '' || (field.nextQuesMapper && field.nextQuesMapper[selectedValue.value] === '')) &&
          this.props.renderSubmitButton(selectedValue.value)}
      </FormItem>
    );
  }
}

SingleChoice.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    options: PropTypes.shape({
      label: PropTypes.string,
      choices: PropTypes.object,
    }),
    error: PropTypes.string,
  }).isRequired,
  changeHandler: PropTypes.func.isRequired,
  renderSubmitButton: PropTypes.func,
};

export default SingleChoice;
