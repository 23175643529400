import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@patient-access/ui-kit';

import { useStateValue } from '../../context';
import { getMinorAilmentsFormData } from '../../data';
import { doSubmitForm } from '../../context/dispatcher';
import TriageForm from '../../components/Triage/TriageForm';
import Button from '../../components/Triage/TriageOverlayButton';
import { postMessageToParent } from '../../components/Common/Helpers';

const MinorAilmentsFormView = ({ location }) => {
    const formName = 'minorailments';
    const [inputFormData, setInputFormData] = useState(null);
    const [state, dispatch] = useStateValue();
    const { overlay } = state.triage || {};

    useEffect(() => {
        const data = getMinorAilmentsFormData();
        setInputFormData(data);
    }, []);

    const submissionHandler = async (data) => {
        const submissionResult = await doSubmitForm(location, formName)(state, dispatch);
        if (submissionResult && submissionResult.submissionId) {
            const messageToParentApp = Object.assign({}, submissionResult, data);
            postMessageToParent(messageToParentApp);
        }
    };

    const handleExitButtonClick = async (e) => {
        await submissionHandler({ createReferral: false, patientDeclined: false });
    };

    const handleCreateReferralButtonClick = async (e) => {
        await submissionHandler({ createReferral: true, patientDeclined: false });
    };

    const handlePatientDeclinedButtonClick = async (e) => {
        await submissionHandler({ createReferral: false, patientDeclined: true });
    };

    const renderOverlayButtons = (isRedFlag) => {
        if (isRedFlag) {
            return <Button buttonType="primary"
                messageKey="cds.triage.button.exit"
                onClick={handleExitButtonClick} />
        }

        return (
            <Fragment>
                <Button buttonType="primary"
                    messageKey="cds.triage.button.createreferral"
                    onClick={handleCreateReferralButtonClick} />
                <Button buttonType="outline"
                    messageKey="cds.triage.button.patientdecilned"
                    onClick={handlePatientDeclinedButtonClick} />
            </Fragment>
        );
    };

    if (!inputFormData) {
        return <Loader type="block" size="medium" className="cds-loader" />
    }

    return (
        <TriageForm inputFormData={inputFormData}>
            {renderOverlayButtons(overlay && overlay.isRedFlag)}
        </TriageForm>
    );
}

MinorAilmentsFormView.propTypes = {
    location: PropTypes.any,
};
export default MinorAilmentsFormView;
