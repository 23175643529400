import { unauthenticatedPost, api } from './httpClient';
import { getWidgetVersion } from '../components/Common/Helpers/envService';

const cdsFormSubmit = async (questionnaireId, answers, params) => {
  const url = api('/questionnaire/submission');
  const widgetVersion = getWidgetVersion();
  const body = {
    "questionnaireId": questionnaireId,
    "answers": answers,
    "widgetVersion": widgetVersion,
    "params": params,
  };

  const result = await unauthenticatedPost(url, body);
  result.questionnaireId = questionnaireId;

  return result;
}


export {
  cdsFormSubmit,
}