import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormItem, Select as UiSelect } from '@patient-access/ui-kit';
import FieldLabel from './FieldLabel';
import { renderClearSelection, renderValidationMessage } from '../Helpers/ui';
import HeaderElement from './HeaderElement';
import ContentElement from './ContentElement';

class Select extends Component {
  render() {
    const { field, changeHandler } = this.props;
    return (
      <FormItem type="item" error={field.error} dataId={`form-select-${field.id}`}>
        <HeaderElement>
          <FieldLabel field={field} faux />
        </HeaderElement>
        <ContentElement>
          <UiSelect
            id={field.id}
            name={field.id}
            isSelected={!!field.value}
            onChange={event => changeHandler(event.target.value, field.id)}
            value={field.value ? field.value : ''}
            required={field.options.required}
          >
            <option value="" disabled>{field.options.placeholder}</option>
            {Object.entries(field.options.choices).map(choice => (
              <option
                id={`${field.id}_${choice[0]}`}
                value={choice[0]}
                key={choice[0]}
              >
                {choice[1].displayValue}
              </option>
            ))}
          </UiSelect>
        </ContentElement>
        {renderValidationMessage(field.error)}
        {renderClearSelection(field, changeHandler)}
      </FormItem>
    );
  }
}

Select.propTypes = {
  field: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    options: PropTypes.shape({
      label: PropTypes.string,
      required: PropTypes.bool,
      choices: PropTypes.object,
      placeholder: PropTypes.string,
    }),
    value: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
  changeHandler: PropTypes.func.isRequired,
};

export default Select;
