import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FormItem, Input } from '@patient-access/ui-kit';

import FieldLabel from './FieldLabel';
import { renderClearSelection, renderValidationMessage } from '../Helpers/ui';
import HeaderElement from './HeaderElement';
import ContentElement from './ContentElement';

class InputDate extends Component {
  render() {
    const { field, changeHandler } = this.props;
    return (
      <FormItem type="item" error={field.error} dataId={`form-date-${field.id}`}>
        <HeaderElement>
          <FieldLabel field={field} faux />
        </HeaderElement>
        <ContentElement>
          <Input
            type="date"
            id={field.id}
            name={field.id}
            onChange={event => changeHandler(event.target.value, field.id)}
            value={field.value ? field.value : ''}
            required={field.options.required}
            placeholder="dd/mm/yyyy"
          />
        </ContentElement>
        {renderValidationMessage(field.error)}
        {renderClearSelection(field, changeHandler)}
      </FormItem>
    );
  }
}

InputDate.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    options: PropTypes.shape({
      label: PropTypes.string,
      required: PropTypes.bool,
    }).isRequired,
    value: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
  changeHandler: PropTypes.func.isRequired,
};

export default InputDate;
