import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import Button from '../Triage/TriageOverlayButton';
import TriageOverlay from '../Triage/TriageOverlay';

const SubmitWrapper = ({ submissionHandler, submissionOverlayContent, canShowSubmitButton}) => {
    const scrollRef = useRef(null)
    const executeScroll = () => scrollRef.current.scrollIntoView({ behavior: "smooth"})

    useEffect(() => {
        canShowSubmitButton && executeScroll()
      }, [canShowSubmitButton]);
      
    const SubmitButton = () => {
        return(
            <Button buttonType="primary"
                        messageKey="cds.triage.button.submit"
                        onClick={submissionHandler} />
        )
    }

    return(
        <>
            {canShowSubmitButton ?
                <div ref={scrollRef} className='cds-submit-button'>            
                    <SubmitButton />
                </div>
            :
                <TriageOverlay content={submissionOverlayContent} visible={true}>
                    <SubmitButton/>
                </TriageOverlay>
            }
        </>
    )     
}

SubmitWrapper.propTypes = {
    hasOutcomePage: PropTypes.bool,
    isContinueButtonClicked: PropTypes.bool,
    submissionHandler: PropTypes.any,
    submissionOverlayContent: PropTypes.any,
  };

export default SubmitWrapper;