const LOCALE = process.env.REACT_APP_LOCALE || 'en-GB';
const privacyPolicyUrl = 'https://support.patientaccess.com/privacy-policy';
const CORONACHECKERURL = 'https://www.patientaccess.com/coronavirus';
const NONEOFTHEABOVE = "noneoftheabove";
const APPIDS = {
  PATIENTACCESS: 'patientaccess',
  PADOTCOM: 'padotcom',
  REFERRALS: 'referrals',
};

const PA_SCREENS = {
  HOME: 'home',
  SERVICES: 'services',
};

const MEDICAL_CONDITIONS = [
  'Diabetes',
  'Heart disease',
  'Asthma or other long-term lung problems including bronchitis',
  'Learning disability',
  'Cancer',
  'Spleen problems',
  'Long-term liver disease',
  'Weakened immune system',
  'Multiple sclerosis and other long-term nervous system conditions',
  'Long-term kidney disease',
  'Had a stroke',
];

const ELEVEN_TO_SEVENTEEN_ELIGIBILITY_CONDITIONS = [
  'They are pregnant.',
  'They are over 16 and a carer receiving carer\'s allowance.',
  'They are severely obese with a BMI (body mass index) over 40.',
];

const AGE_GROUP_RESTRICTION = {
  NORESTRICTION: 'noRestriction',
  UNDERSIXMONTHS: 'underSixMonths',
  SIXMONTHSTOTWOYEARSORELEVENYEARSTOSEVENTEENYEARS: 'sixMonthsToTwoYearsOrElevenYearsToSeventeenYears',
  TWOYEARSTOTENYEARS: 'twoYearsToTenYears',
  TWOYEARSTOELEVENYEARS: 'twoYearsToElevenYears',
  SIXMONTHSTOTWOYEARS: 'sixMonthsToTwoYears',
  ELEVENYEARSTOSEVENTEENYEARS: 'elevenYearsToSeventeenYears',
  TWELVEYEARSTOSEVENTEENYEARS: 'twelveYearsToSeventeenYears',
  FIFTYYEARSTOSIXTYFOURYEARS: 'fiftyYearsToSixtyFourYears',
  // Age restrictions added as part of flu 2021
  TWOYEARSTOFIFTEENYEARS: 'twoYearsToFifteenYears',
  SIXTEENYEARSTOSEVENTEENYEARS: 'sixteenYearsToSeventeenYears',
  EIGHTEENYEARSTOFORTYNINEYEARS: 'eighteenYearsToFortyNineYears',
  OVERFIFTYYEARS: 'overFiftyYears',
  // added for flu 2022 questionnaire
  ELEVENYEARSTOFIFTEENYEARS: 'elevenYearsToFifteenYears',
  OVERSIXTYFIVEYEARS: 'overSixtyFiveYears',
};

const SCOTLAND_ELIGIBILITY = {
  FIFTYTOFIFTYFOUR_AGEGROUP: 'https://www.hps.scot.nhs.uk/publications/hps-weekly-report/volume-54/issue-33/more-people-in-scotland-to-be-eligible-for-free-flu-vaccination/',
  FIFTYFIVETOSIXTYFOUR_AGEGROUP: 'http://www.healthscotland.scot/health-topics/immunisation/flu-immunisation-programme/adults',
};

const WIDGET_TYPE = {
  ASTHMA_EXA: 'asthmaExa',
  FLU: 'flu',
};

const EXTERNAL_URL = {
  ASTHMA: "https://www.giveyourlungsavoice.co.uk"
};

export {
  LOCALE,
  privacyPolicyUrl,
  CORONACHECKERURL,
  NONEOFTHEABOVE,
  APPIDS,
  PA_SCREENS,
  MEDICAL_CONDITIONS,
  ELEVEN_TO_SEVENTEEN_ELIGIBILITY_CONDITIONS,
  AGE_GROUP_RESTRICTION,
  SCOTLAND_ELIGIBILITY,
  WIDGET_TYPE,
  EXTERNAL_URL,
};
