import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const ExternalLink = ({ link, id }) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    <FormattedMessage id={id} defaultMessage="click here" />
  </a>
);

ExternalLink.propTypes = {
  link: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default ExternalLink