const HttpErrors = {
    BAD_REQUEST: 'bad_request',
    CONNECTION_ERROR: 'connection_error',
    UNAUTHORIZED: 'unauthorized',
    FORBIDDEN: 'forbidden',
    NOT_FOUND: 'not_found',
    CONFLICT: 'conflict',
    UNPROCESSABLE_ENTITY: 'unprocessable_entity',
    SERVER_ERROR: 'server_error',
    UNKNOWN: 'unknown',
  };

  export default HttpErrors;