import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Loader, Form } from '@patient-access/ui-kit';
import fluData from '../../data/fluQuestionnaire.json';
import getFormElement from '../Forms/FormElements/formElementFactory';
import { ScrollForm, FormHeader } from '../Common';
import { cdsFormSubmit } from '../../sideEffects/cdsDispatcher';
import ResultView from './ResultView';
import { NONEOFTHEABOVE } from '../../constant';
import { FormattedMessage } from 'react-intl';
import { isIOSNative, isAndroidNative, postMessageToParent, isPatientAccessApp } from '../Common/Helpers';
import PageExitPrompt  from '../Common/PageExitPrompt';

class FormPage extends Component {
  constructor(props) {
    super(props);
    this.isInPatientAccessApp = true;
    if (this.props.appId) {
      this.isInPatientAccessApp = isPatientAccessApp(this.props.appId);
    }

    this.changeHandler = this.changeHandler.bind(this);
    this.renderSubmit = this.renderSubmit.bind(this);
    this.clearNextQuestionsWhileContinue = this.clearNextQuestionsWhileContinue.bind(this);
    this.renderSubmitButton = this.renderSubmitButton.bind(this);
    this.questions = fluData['fluQuestionnaire'];
    this.noExitPrompt = false;
    this.state = {
      defaultId: '1',
      isSynchronising: false,
      isError: false,
      showResultScreen: false,
      lastChangedField: [{
        id: '1',
        key: '',
        value: '',
      }],
    };
    this.answers = {
      subject: "default",
      age: "default",
      pregnant: "skipped",
      medicalConditions: [],
      medicalConditionChanged: "skipped",
      invitedLast12Months: "skipped",
      environment: "none",
      allergies: [],
      exclusions: [],
      employment: "none",
    };

    this.formResult = null;
    this.tempLastChangedField = [{
      id: '1',
      key: '',
      value: '',
    }];
  }

  clearUpdatedQuestion(value, currentQuestion, currentQuesindex) {
    let tempLastChanged = this.state.lastChangedField;
    this.state.lastChangedField.map((data, index) => {

      if (currentQuesindex < index) {
        this.questions.map(query => parseInt(query.id) === parseInt(data.id) ? query.state = 'INACTIVE' : '');
        tempLastChanged = tempLastChanged.filter(item => parseInt(item.id) !== parseInt(data.id));
      }
      if (data.id === currentQuestion.id) {
        tempLastChanged.map(query => query.id === data.id ?
          (query.value = value, query.key = currentQuestion.field) : '');
      }
      return null;
    }, this);
    this.tempLastChangedField = tempLastChanged;
  }

  getNextQuestionMapper(currentQuestion) {
    let nextQuesMapperKey = 'nextQuesMapper';
    if (currentQuestion.mapperFinder) {
      const validMapperFinder = currentQuestion.mapperFinder.find(mapperFinderItem => {

        return mapperFinderItem.conditions.every(condition => {
          const field = this.state.lastChangedField.find(answeredfield =>
            answeredfield.key === condition.field);

          return condition.answer.includes(field.value);
        });
      });

      if (validMapperFinder && validMapperFinder.mapper) {
        nextQuesMapperKey = validMapperFinder.mapper;
      }
    }

    return nextQuesMapperKey;
  }

  changeNextQuestion(value, currentQuestion) {
    let nextQuesMapperKey = this.getNextQuestionMapper(currentQuestion);

    if (currentQuestion.type === 'MultiChoiceWithContinue') {

      this.nextQuestion = Array.from(new Set(
        Object.keys(currentQuestion[nextQuesMapperKey]).map((i) => {
          return value.indexOf(i) !== -1 ? currentQuestion[nextQuesMapperKey][i] : null;
        })
      ));

      this.nextQuestion = this.nextQuestion.filter(Boolean);

      const prioritizedOption = currentQuestion.prioritizedOptions.find(prioritizedOption => {
        return (this.nextQuestion.indexOf(prioritizedOption) !== -1)
      });

      this.nextQuestionMapper = prioritizedOption || this.nextQuestion[0];

    } else {
      this.nextQuestionMapper = currentQuestion[nextQuesMapperKey][value];
    }

    if (this.nextQuestionMapper !== '') {
      this.questions.map(q => q.id === this.nextQuestionMapper ? q.state = 'ACTIVE' : '');
      const nextdata = this.tempLastChangedField.filter(query => query.id === this.nextQuestionMapper);

      this.tempLastChangedField = (nextdata.length === 0) ?
        this.tempLastChangedField.concat([{ id: this.nextQuestionMapper, key: '', value: '' }]) : this.tempLastChangedField;
    }

    this.setState({
      lastChangedField: this.tempLastChangedField
    });
  }

  changeHandler(value, fieldId, choiceId) {
    this.state.isError && this.setState({ isError: false });
    //this.querys.filter(query => query.id === fieldId ? query.selectedValue=value :'')
    this.currentQuestion = this.questions.filter(query => query.id === fieldId);
    this.isPreviousQuestion = this.state.lastChangedField.filter(query => query.id === fieldId);
    this.currentQuesindex = this.state.lastChangedField.findIndex(query => query.id === fieldId);
    this.clearUpdatedQuestion(value, this.currentQuestion[0], this.currentQuesindex);
    if (this.currentQuestion[0].nextQuesMapper !== '') {
      this.changeNextQuestion(value, this.currentQuestion[0]);
    }
  }

  clearNextQuestionsWhileContinue(fieldId) {
    let tempLastChanged = this.state.lastChangedField;
    this.currentIndex = this.state.lastChangedField.findIndex(query => query.id === fieldId);
    this.state.lastChangedField.map((data, index) => {

      if (this.currentIndex < index) {
        this.questions.map(query => parseInt(query.id) === parseInt(data.id) ? query.state = 'INACTIVE' : '');
        tempLastChanged = tempLastChanged.filter(item => parseInt(item.id) !== parseInt(data.id));
      }
      return null;
    }, this);
    this.setState({
      lastChangedField: tempLastChanged
    });
    this.tempLastChangedField = tempLastChanged;
  }

  async renderSubmit() {
    this.state.lastChangedField.map((data) => {
      if (this.answers[data.key]) {
        this.answers[data.key] = Array.isArray(data.value) ?
          (data.value[0] === NONEOFTHEABOVE ? [] : data.value)
          : data.value;
      }
      return null;
    }, this);

    this.setState({ isSynchronising: true });

    const { appId, sessionId, utmSource } = this.props;
    const params = {
      sessionId: sessionId,
      pageName: utmSource,
      appId: appId,
    };

    const { flu } = window.__ENV.questionnaireId || {};
    const questionnaireId = flu;
    if (questionnaireId === 'flu' && this.answers) {
      this.answers.medicalConditions = typeof this.answers.medicalConditions === 'string' ? [this.answers.medicalConditions] : [];
      this.answers.allergies = typeof this.answers.allergies === 'string' ? [this.answers.allergies] : [];
    }
    try {
      this.formResult = await cdsFormSubmit(questionnaireId, this.answers, params);
      if (this.formResult)
        this.noExitPrompt = true;
      if (this.isInPatientAccessApp) {
        postMessageToParent(this.formResult);
      }
      else {
        this.setState({
          isSynchronising: false,
          isError: false,
          showResultScreen: true,
        });
      }
    } catch (error) {
      this.setState({
        isSynchronising: false,
        isError: true,
        showResultScreen: false,
      });
    }
  }

  renderSubmitButton(value) {
    return <Button
      className="cds-buttons"
      id="Submit"
      messageKey="cds.button.submitanswers"
      buttonType="primary"
      buttonLength="half"
      buttonSize="medium"
      defaultMessage="Submit answers"
      isDisabled={value === 0}
      onClick={(e) => {
        e.preventDefault();
        this.renderSubmit();
      }}
    />;
  }

  renderFormElements() {
    if (this.questions) {
      const listquestionsDetails = this.state.lastChangedField.map((data, index) => {
        this.selectedValue = this.questions.filter(field => field.id === data.id);
        if (this.selectedValue.length > 0 && this.selectedValue[0].state === 'ACTIVE') {
          return (<div className="page" key={index}>
            {getFormElement(this.selectedValue[0],
              this.changeHandler,
              data,
              this.clearNextQuestionsWhileContinue,
              this.renderSubmitButton,
              index + 1)}
          </div>);
        }
        return null;
      }, this);
      return listquestionsDetails;
    }
  }

  renderErrorMessage() {
    if (this.state.isError) {
      return (
        <div className="cds-error">
          <FormattedMessage id="cds.error" defaultMessage="Sorry unexpected error occured. Please try later." />
        </div>);
    }
  }

  renderSuccess() {
    if (this.state.isSynchronising) {
      return (
        <Loader type="block" size="medium" className="cds-loader" />
      );
    }
    if (this.state.showResultScreen) {
      return (
        <div className="cds-wrapper cds-wrapper-medium">
          <ResultView result={this.formResult} isFlu />
        </div>
      );
    } else {
      return (
        <>
          <div className="cds-wrapper cds-wrapper-medium">
            <PageExitPrompt noExitPrompt={this.noExitPrompt}>
              <Form>
                <ScrollForm
                  selectorClass='page'
                  activeClass='active'>
                  {this.renderFormElements()}
                </ScrollForm>
                {this.renderErrorMessage()}
              </Form>
            </PageExitPrompt>
          </div>
        </>
      );
    }
  }

  render() {
    return (
      <Fragment>
        {this.renderSuccess()}
      </Fragment>
    );
  }
}

FormPage.propTypes = {
  appId: PropTypes.string,
  sessionId: PropTypes.string,
  utmSource: PropTypes.string,
};

export default FormPage;
