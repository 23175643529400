import React from 'react';
import PropTypes from 'prop-types';
import { Overlay as UIOverlay, Markup } from '@patient-access/ui-kit';

const TriageOverlay = ({ content, visible, children }) => {
  if (!(content && content.length > 0)) return null;

  const formatContent = (data) => {
    const formattedContent = data.replace(/(\\n|\\'|\\)+/g, '');
    return formattedContent;
  }

  return (
    <UIOverlay
      isOpen={visible}
      showCloseButton={false}
      background="dark"
      type="triage"
      dataId="overlay-triage"
      doClose={() => { }}
    >
      <div className="overlay-triage-content" tabIndex={0} role="text">
        <Markup>
          <div dangerouslySetInnerHTML={{ __html: formatContent(content) }} />
        </Markup>
      </div>
      <div className="overlay-triage-buttons">
        {children}
      </div>
    </UIOverlay>
  );
}

TriageOverlay.propTypes = {
  content: PropTypes.string,
  visible: PropTypes.bool,
  children: PropTypes.node,
};
export default TriageOverlay;