import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@patient-access/ui-kit';

import { useStateValue } from '../../context';
import { getPrincipleTrialExaFormData } from '../../data';
import TriageForm from '../../components/Triage/TriageForm';
import Button from '../../components/Triage/TriageOverlayButton';
import { doSubmitForm, doStartForm } from '../../context/dispatcher';
import { postMessageToParent } from '../../components/Common/Helpers';

const PrincipleTrialExaFormView = ({ location }) => {
    const formName = 'principleTrialExa';
    const [inputFormData, setInputFormData] = useState(null);
    const [state, dispatch] = useStateValue();
    const { userData, status } = state.triage || {};
    const { isLastPage } = userData || {};
    const submissionOverlayContent = '<p><strong>The questionnaire is now complete.</strong></p>\\n<p>Thank you for taking the time to participate.  Your answers are helping the nation, researchers and clinicians understand more about tackling COVID-19.</p>\\n<div>Please click on submit (below) to submit your answers and close the questionnaire.</div>';

    useEffect(() => {
        const data = getPrincipleTrialExaFormData();
        setInputFormData(data);
    }, []);

    const submissionHandlerFireAndForget = () => {
        doSubmitForm(location, formName)(state, dispatch);
        postMessageToParent({ submissionId: '', questionnaireId: '' });
        doStartForm()(dispatch);
    };

    const renderOverlayButtons = () => (
        <Button buttonType="primary"
            messageKey="cds.triage.button.exit"
            onClick={submissionHandlerFireAndForget} />
    );

    if (!inputFormData || (status && status.isSynchronising)) {
        return <Loader type="block" size="medium" className="cds-loader" />
    }

    return (
        <Fragment>
            <TriageForm inputFormData={inputFormData} isLastPage={isLastPage} submissionHandler={submissionHandlerFireAndForget} submissionOverlayContent={submissionOverlayContent}>
                {renderOverlayButtons()}
            </TriageForm>
        </Fragment>
    );
}

PrincipleTrialExaFormView.propTypes = {
    location: PropTypes.any,
};
export default PrincipleTrialExaFormView;