import React from 'react';
import PropTypes from 'prop-types';

const ContentElement = ({ children }) => {
  return (
    <div className="cds-step-content">
        {children}
    </div>
  );
};

ContentElement.propTypes = {
    children: PropTypes.any,
};
export default ContentElement;
