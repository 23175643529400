import cloneDeep from 'lodash.clonedeep';

const fieldMatchesCondition = (field, condition) => {
  if (field.type === 'Checkbox') {
    return Boolean(field.options.choices[condition.formRowOption].isSelected);
  }

  return field.value === condition.formRowOption;
};

const matchesConditions = (state, nextPage) => {
  if (!nextPage) {
    return true;
  }

  if (!nextPage.conditions || nextPage.conditions.length === 0) {
    return true;
  }

  let match = true;
/*eslint-disable no-unused-vars*/
  for (const condition of nextPage.conditions) {
    let fieldFound = false;
    for (const page of state.triage.userData.pages) {
      const field = page.fields.find(f => f.id === Number(condition.formRow));
      if (field) {
        fieldFound = true;
        if (nextPage.operator === 'OR') {
          if (fieldMatchesCondition(field, condition)) {
            return true;
          }
          match = false;
        }

        if (nextPage.operator === 'AND') {
          if (!fieldMatchesCondition(field, condition)) {
            match = false;
          }
        }
      }
    }

    if (!fieldFound) {
      return false;
    }
  }

  return match;
};

const getNextApiPageIndex = (state, nextIndex) => {
  const nextApiPage = state.triage.formData.pages[nextIndex];
  if (matchesConditions(state, nextApiPage)) {
    return nextIndex;
  }

  return getNextApiPageIndex(state, nextIndex + 1);
};

const getApiPageIndex = (state, apiFormIndex) => {
  const { triage: { formData: { currentPageIndex } } } = state;
  const index = (apiFormIndex) || currentPageIndex + 1;

  return getNextApiPageIndex(state, index);
};

const getApiFormPage = (state, pageIndex) => {
  if (state.triage.formData.pages[pageIndex]) {
    const newPage = cloneDeep(state.triage.formData.pages[pageIndex]);
    newPage.formDataIndex = pageIndex;

    return newPage;
  }

  return null;
};

const movePageIndex = (state, index) => Object.assign({}, state, {
  triage: {
    ...state.triage,
    userData: {
      ...state.triage.userData,
      currentPageIndex: index,
    },
  },
});

const addNewPage = (state, userPageIndex, apiFormIndex) => {
  const apiPageIndex = getApiPageIndex(state, apiFormIndex);
  const page = getApiFormPage(state, apiPageIndex);
  if (page) {
    return Object.assign({}, state, {
      triage: {
        ...state.triage,
        formData: {
          ...state.triage.formData,
          currentPageIndex: apiPageIndex,
        },
        userData: {
          ...state.triage.userData,
          currentPageIndex: userPageIndex,
          isLastPage: false,
          pages: [
            ...state.triage.userData.pages.slice(0, userPageIndex),
            page,
          ],
        },
      },
    });
  }

  // just change index
  return Object.assign({}, state, {
    triage: {
      ...state.triage,
      formData: {
        ...state.triage.formData,
        currentPageIndex: apiPageIndex,
      },
      userData: {
        ...state.triage.userData,
        currentPageIndex: userPageIndex,
        isLastPage: true,
      },
    },
  });
};

// const hasSkippedPages = (state, nextUserPageIndex) => {
//   if (nextUserPageIndex === 0) return false;

//   const currentUserPageIndex = nextUserPageIndex - 1;

//   const currentApiFormIndex = state.triage.userData.pages[currentUserPageIndex].formDataIndex;
//   const nextApiFormIndex = state.triage.userData.pages[nextUserPageIndex].formDataIndex;
//   const diff = nextApiFormIndex - currentApiFormIndex;

//   return diff > 1;
// };

const insertPage = (state, page, indexAt) => Object.assign({}, state, {
  triage: {
    ...state.triage,
    formData: {
      ...state.triage.formData,
    },
    userData: {
      ...state.triage.userData,
      currentPageIndex: indexAt,
      pages: [
        ...state.triage.userData.pages.slice(0, indexAt),
        page,
        ...state.triage.userData.pages.slice(indexAt, state.triage.userData.pages.length),
      ],
    },
  },
});

const removePage = (state, indexAt) => Object.assign({}, state, {
  triage: {
    ...state.triage,
    formData: {
      ...state.triage.formData,
    },
    userData: {
      ...state.triage.userData,
      currentPageIndex: indexAt,
      pages: [
        ...state.triage.userData.pages.slice(0, indexAt),
        ...state.triage.userData.pages.slice(indexAt + 1, state.triage.userData.pages.length),
      ],
    },
  },
});

const removePages = (state, indexAt, formDataIndex) => Object.assign({}, state, {
  triage: {
    ...state.triage,
    formData: {
      ...state.triage.formData,
      currentPageIndex: formDataIndex,
    },
    userData: {
      ...state.triage.userData,
      currentPageIndex: indexAt - 1,
      pages: [
        ...state.triage.userData.pages.slice(0, indexAt)
      ],
    },
  },
});

// const getNextSkippedPage = (state, indexFrom, indexTo) => {
//   let index = indexFrom;
//   while (index < indexTo) {
//     const page = state.triage.formData.pages[index];
//     if (matchesConditions(state, page)) {
//       page.formDataIndex = index;
//       return page;
//     }
//     index += 1;
//   }

//   return null;
// };

const getNextPage = (state, nextUserPageIndex) => {
  const nextUserPage = state.triage.userData.pages[nextUserPageIndex];
  if (nextUserPage) {
    const formDataIndex = state.triage.userData.pages[nextUserPageIndex - 1].formDataIndex;
    return getNextPage(removePages(state, nextUserPageIndex, formDataIndex), nextUserPageIndex);
  }

  return addNewPage(state, nextUserPageIndex);
};

const getPreviousPage = (state, prevPageIndex) => {
  if (state.triage.userData.pages[prevPageIndex]) {
    return movePageIndex(state, prevPageIndex);
  }

  return state;
};

const test = {
  matchesConditions,
  insertPage,
  removePage,
  fieldMatchesCondition,
};

export {
  getNextPage,
  getPreviousPage,
  test,
  removePages,
};
