import isEmpty from 'lodash.isempty';

import { isInputField } from '../../components/Forms/Helpers';

const getMultipleValue = field => Object.entries(field.options && field.options.choices)
  .filter((choiceEntry) => (field.type === 'Checkbox') ? Boolean(choiceEntry[1].isSelected) : choiceEntry[0] === field.value)
  .map(choiceEntry => {
    const value = {
      name: choiceEntry[1].displayValue,
    };
    if ((!isEmpty(field.alerts) && typeof field.alerts[choiceEntry[0]] !== 'undefined')) {
      value['alert'] = field.alerts[choiceEntry[0]];
    }
    return value;
  });

const getValue = (field) => {
  const { type } = field;
  if (type === 'Select' || type === 'Radio' || type === 'Checkbox') {
    return getMultipleValue(field);
  }
  return field.value;
};

const adaptField = field => ({
  id: field.id,
  question: (field.options && field.options.label) || '',
  value: getValue(field),
});

const adaptFormForCds = (pages) => {
  const adaptedForm = {};
  pages && pages.map(page => {
    adaptedForm[page.name] = page.fields && page.fields.filter(isInputField).map(adaptField);
    return page;
  });

  return adaptedForm;
};

export default adaptFormForCds