import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FormItem, Input } from '@patient-access/ui-kit';

import FieldLabel from './FieldLabel';
import { renderValidationMessage } from '../Helpers/ui';
import HeaderElement from './HeaderElement';
import ContentElement from './ContentElement';

class Text extends Component {
  render() {
    const { field, changeHandler } = this.props;
    return (
      <FormItem type="item" error={field.error} dataId={`form-text-${field.id}`}>
        <HeaderElement>
          <FieldLabel field={field} faux />
        </HeaderElement>
        <ContentElement>
          <Input
            type="text"
            id={field.id}
            name={field.id}
            onChange={event => changeHandler(event.target.value, field.id)}
            maxLength={255}
            value={field.value ? field.value : ''}
            required={field.options.required}
          />
          {renderValidationMessage(field.error)}
        </ContentElement>
      </FormItem>
    );
  }
}

Text.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    options: PropTypes.shape({
      label: PropTypes.string,
      required: PropTypes.bool,
    }),
    value: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
  changeHandler: PropTypes.func.isRequired,
};

export default Text;
