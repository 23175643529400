import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import MediaQuery from 'react-responsive';
import { Button } from '@patient-access/ui-kit';
import { privateFluInAppUrl, privateFluPADotComUrl } from '../../sideEffects/urlMapper';
import { isIOSNative, isAndroidNative, isPatientAccessApp } from './Helpers';
import AsthmaCoverPageDescription from './AsthmaCoverPageDescription';
import FluCoverPageDescription from './FluCoverPageDescription';
import { WIDGET_TYPE } from '../../constant.js';

class CoverPageView extends Component {
  constructor(props) {
    super(props);

    const { appId, appVersion, widgetType } = this.props;
    this.isFlu = widgetType === WIDGET_TYPE.FLU;
    this.isAsthmaExa = widgetType === WIDGET_TYPE.ASTHMA_EXA;
    this.isInPatientAccessApp = true;
    if (appId) {
      this.isInPatientAccessApp = isPatientAccessApp(appId);
    }

    const isNative = (isIOSNative() || isAndroidNative()) && this.isInPatientAccessApp;
    this.url = this.isInPatientAccessApp ? privateFluInAppUrl() : privateFluPADotComUrl();
    const versionNumber = (appVersion && appVersion.replace(/\./g, ''));
    this.canShowBookPrivateFlu = isNative ? versionNumber >= 245 : true;
  }

  get getPrivateFluLink() {
    return (
      <a href={this.url} target="_top">
        <FormattedMessage id="cds.coverpage.content.privateFluBook.linkText" defaultMessage="Book it now" />
      </a>
    );
  }

  bookPrivateFluDesktop() {
    if (!this.canShowBookPrivateFlu) {
      return null;
    }
    const bookNow = this.getPrivateFluLink;

    return (
      <MediaQuery minWidth={769}>
        <div className="cds-content cds-content-booknow">
          <p>
            <strong><FormattedMessage id="cds.coverpage.content.privateFlu.mobile" defaultMessage="Looking for a private flu vaccination?" /></strong>
            <strong className="cds-link-booknow"><FormattedMessage id="cds.coverpage.content.privateFluBook" defaultMessage="{bookNow}" values={{ bookNow }} /></strong>
          </p>
        </div>
      </MediaQuery>
    );
  }

  renderBody() {
    if (this.isAsthmaExa) {
      return (
        <AsthmaCoverPageDescription />)
    }
    return (
      <FluCoverPageDescription isFlu={this.isFlu} canShowBookPrivateFlu={this.canShowBookPrivateFlu} getPrivateFluLink={this.getPrivateFluLink} isInPatientAccessApp={this.isInPatientAccessApp} />)
  }

  renderButtons() {
    const { onContinueClick, referenceInfo } = this.props;
    const message = this.isFlu ? "cds.eligible.button" : "Continue with Questionnaire";
    const btnEligibility = (
      <Button
        buttonType="primary"
        className="coverpage-button"
        onClick={event => onContinueClick(event)}
        messageKey={message}
        defaultMessage=""
        data-id="eligible-button"
        buttonSize="medium"
      />
    );

    return (
      <Fragment>
        <MediaQuery maxWidth={767}>
          <div className="cds-button-float">
            <div className="cds-button-wrapper">
              {btnEligibility}
              {referenceInfo && <div className="cds-footer cds-footer-info">{referenceInfo}</div>}
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={768}>
          <div className="cds-buttons">
            {btnEligibility}
          </div>
        </MediaQuery>
      </Fragment>
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderBody()}
        {this.renderButtons()}
        {this.isFlu && this.bookPrivateFluDesktop()}
      </Fragment>
    );
  }
}

CoverPageView.propTypes = {
  onContinueClick: PropTypes.func,
  appId: PropTypes.string,
  appVersion: PropTypes.string,
  widgetType: PropTypes.string,
  referenceInfo: PropTypes.string,
};

export default CoverPageView;
