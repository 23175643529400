import React from 'react';
import PropTypes from 'prop-types';

const HeaderElement = ({ children }) => {
  return (
    <div className="cds-step-header">
        {children}
    </div>
  );
};

HeaderElement.propTypes = {
    children: PropTypes.any,
};
export default HeaderElement;
