const isInputField = field => !['Header', 'Paragraph'].includes(field.type);
const isMultipleField = (field) => {
  if (field && field.type === 'Checkbox') {
    return true;
  }
  return false;
};

const isHighRiskChoice = (choice) => {
  if (choice && choice.isHighRisk === true) {
    return true;
  }
  return false;
};

const isConsentForm = (page) => {
  if (page.name === 'Consent') {
    return true;
  }
  return false;
};

const isOptionalField = (field) => {
  if (field && field.options.required === false) {
    return true;
  }
  return false;
};

const isWritableField = (field) => {
  if (field && !field.readOnly) {
    return true;
  }
  return false;
};

const isSummmaryField = (field) => {
  if (field.summaryDisplay === true) {
    return true;
  }
  return false;
};

const isSelectedChoice = (choice) => {
  if (choice && choice.isSelected) {
    return true;
  }
  return false;
};

export {
  isInputField,
  isMultipleField,
  isHighRiskChoice,
  isConsentForm,
  isOptionalField,
  isWritableField,
  isSummmaryField,
  isSelectedChoice,
};
