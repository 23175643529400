const contents = {
  twoYearsToElevenYears1: 'Your child is eligible for a free flu vaccination as long as they were born between 1 September 2008 and 31 August 2018. {br} {br}',
  twoYearsToElevenYears2: 'If your child was born between 1 September 2016 and 31 August 2018, their vaccination will be provided at your GP practice - please contact your GP practice to arrange a vaccination. {br} {br}',
  twoYearsToElevenYears3: 'If your child was born between 1 September 2008 and 31 August 2016, their vaccination will be provided at their primary or secondary school - please speak to the school. If your child has missed their school vaccination session, or the school vaccination session is later in the season and you would like your child to have their vaccination sooner, please contact your GP practice.',
  sixMTo2YearsOr11To17Years1: 'Flu vaccines are only given to children in this age group if they have a medical risk. {br} Some children in this age group are eligible for free NHS flu vaccination if they have: {medicalConditionsList} {br}',
  sixMTo2YearsOr11To17Years2: '12-17 year-olds are eligible for a free NHS flu vaccination if: {elevenToSeventeenList} {br}',
  sixMTo2YearsOr11To17Years3: 'If any of the above applies, please contact your practice.',
};

const cdsMessages = {
  // Cds:Element
  'cds.button.continue': 'Continue',
  'cds.button.submitanswers': 'Submit answers',

  // Cds:Landing Page (Cover page) - flu 
  'cds.header': 'Find out if you are eligible for a free NHS flu vaccination',
  'cds.subheader1': 'About flu vaccination',
  'cds.subheader2': 'Free NHS flu vaccination',
  'cds.content1': 'The flu vaccination is available every year through the NHS to help protect adults and children at risk of serious complications from flu. Free flu vaccination is offered at GP practices for people in these at-risk groups. Some community pharmacies now offer free (NHS) as well as paid flu vaccinations.',
  'cds.content1.1.flu': 'For 2024-2025 all children and pregnant women will be eligible for a free NHS flu vaccine which will be available from Septrmber 1, 2024. All other people who are eligible for an NHS flu vaccine will be vaccinated from 3 October 2024.',
  'cds.content2': 'This simple eligibility checker contains questions based on the 2020 NHS flu vaccination eligibility criteria. Questions can be answered for yourself or on behalf of someone else. Answering these will help you understand if you are eligible for a free NHS flu vaccination. It will also allow us to guide you to where you can get your vaccination.{br}',
  'cds.content3': 'If the eligibility checker confirms that you\'re not eligible for a free NHS vaccination, you can still book an appointment for a private flu vaccination at a pharmacy via Patient Access.{br}By proceeding, your answers may be used to help show you relevant flu vaccination services on Patient Access in the future if you choose to register or sign in.{br}',
  'cds.eligible.button': 'Find out if you are eligible',
  'cds.policy': 'privacy policy',
  'cds.content4': 'The eligibility checker will take just 1-2 minute(s) to complete.',

  'cds.content1.about': 'The flu vaccination is available every year through the NHS. It aims to provide direct protection to those who are at higher risk of flu-associated complications and to reduce transmission to all age groups through the vaccination of children.',
  'cds.content1.flu': 'For 2024-2025 all children at clinical risk or up to school year 11, and pregnant women will be eligible for a free NHS flu vaccine which will be available from 1 September 2024. All other people who are eligible for an NHS flu vaccine will be vaccinated from 3 October 2024.',
  'cds.content2.flu': 'This simple eligibility checker contains questions based on the 2024-2025 NHS flu vaccination eligibility criteria. Questions can be answered for yourself or on behalf of someone else. Answering these will help you understand if you are eligible for a free NHS flu vaccination. It will also allow us to guide you to where you can get your vaccination.{br}',
  'cds.content3.flu': 'If the eligibility checker confirms that you\'re not eligible for a free NHS vaccination, you can still book an appointment for a private flu vaccination at a pharmacy via Patient Access. Please note this flu checker is based on the guidelines in England. There may be variations to guidelines for the flu vaccine in other parts of the UK.{br}{br}By proceeding, your answers may be used to help show you relevant flu vaccination services on Patient Access in the future.',
  'cds.content3.flu.notSignedIn': 'If the eligibility checker confirms that you\'re not eligible for a free NHS vaccination, you can still book an appointment for a private flu vaccination at a pharmacy via Patient Access. Please note this flu checker is based on the guidelines in England. There may be variations to guidelines for the flu vaccine in other parts of the UK.{br}{br}By proceeding, your answers may be used to help show you relevant flu vaccination services on Patient Access in the future if you choose to register or sign in.',

  // Cds:ResultView - flu

  // underSixMonths
  'cds.flu.result.header.underSixMonths': 'Eligibility advice',
  'cds.flu.result.content.underSixMonths': 'We are sorry, but flu vaccination is not licensed for children aged under 6 months.',
  // sixMonthsToTwoYears
  'cds.flu.result.header.sixMonthsToTwoYears': 'Eligibility advice',
  'cds.flu.result.content.sixMonthsToTwoYears':'<p>Children under 2 years old are not routinely eligible for NHS flu vaccination.</p><p>However, some children with underlying health conditions such as diabetes or conditions affecting the lungs, heart, kidneys, liver, nervous system or immune system may be eligible from 1 September 2024 onwards.</p><p>If your child has an underlying health condition, please contact your GP practice to see if they need a flu vaccination.</p><p>Please also note that flu vaccination may not be suitable for:</p> <ul><li><p>People with a severe egg allergy.</p></li><li><p>People who have had a severe allergic reaction to a previous dose of flu vaccine. </p></li></ul>',
  // twoYearsToTenYears
  'cds.flu.result.header.twoYearsToTenYears': 'Eligible for a free NHS flu vaccination from 1 September 2024  onwards',
  'cds.flu.result.content.twoYearsToTenYears': '<p>All children aged 2 to 10 years (up to and including all year 6 pupils) will be offered the flu vaccine.</p> <p>Those aged 2-3 years (born between 1 September 2020 and 31 August 2022) will have their flu vaccination at their GP practice - please wait to be contacted by your GP practice.</p> <p>All primary school-aged children up to and including year 6 will have their flu vaccine at school. Please wait for the school-age immunisation service to contact you.</p> <p>Please also note that flu vaccination may not be suitable for:</p> <ul><li><p>People with a severe egg allergy.</p></li><li><p>People who have had a severe allergic reaction to a previous dose of flu vaccine. </p></li></ul>',
  // elevenYearsToFifteenYears
  'cds.flu.result.header.elevenYearsToFifteenYears': 'Eligibility advice',
  'cds.flu.result.content.elevenYearsToFifteenYears': '<p>All school-aged children (including those home-schooled or in non-mainstream education) will be offered the flu vaccine from years 7 to 11 inclusive from 1 September 2024  onwards. Please wait to be contacted by your vaccine provider.</p>',
  // sixteenYearsToSeventeenYears
  'cds.flu.result.header.sixteenYearsToSeventeenYears.maybeeligible': 'You are eligible for NHS flu vaccination from 1 September 2024  onwards',
  'cds.flu.result.content.sixteenYearsToSeventeenYears.maybeeligible': '<p>Your answers suggest that you are eligible for free NHS flu vaccination.</p> <p>Please wait to be contacted by your GP practice. If you have not heard from them by end of September, please contact the practice.</p><p>Please also note that flu vaccination may not be suitable for:</p> <ul><li><p>People with a severe egg allergy.</p></li><li><p>People who have had a severe allergic reaction to a previous dose of flu vaccine. </p></li></ul>',
  'cds.flu.result.header.sixteenYearsToSeventeenYears': 'You are eligible for a free NHS flu vaccination from 3 October 2024 onwards',
  'cds.flu.result.header.sixteenYearsToSeventeenYears.eligible': 'You are eligible for NHS flu vaccination from 3 October 2024 onwards',
  'cds.flu.result.content.sixteenYearsToSeventeenYears.eligible': '<p>Your answers suggest that because of your work, you are eligible for an NHS flu vaccination.</p><p>This should be provided by your employer – please contact them for details.</p> <p>If you cannot get an NHS flu vaccination through your work, you may be able to get an NHS flu vaccination through your GP practice or through the NHS.</p><p>Please also note that flu vaccination may not be suitable for:</p> <ul><li><p>People with a severe egg allergy.</p></li><li><p>People who have had a severe allergic reaction to a previous dose of flu vaccine.</p> </li></ul>',
  // eighteenYearsToFortyNineYears
  'cds.flu.result.header.eighteenYearsToFortyNineYears.maybeeligible': 'May be eligible for NHS flu vaccination from 1 September 2024  onwards',
  'cds.flu.result.content.eighteenYearsToFortyNineYears.maybeeligible': '<p>Your answers suggest that you are or may be eligible for free NHS flu vaccination.</p> <p>Please wait to be contacted by your GP practice. If you have not heard from them by end of October, please contact the practice.</p><p>Please also note that flu vaccination may not be suitable for:</p> <ul><li><p>People with a severe egg allergy.</p></li><li><p>People who have had a severe allergic reaction to a previous dose of flu vaccine. </p></li></ul>',
  'cds.flu.result.header.eighteenYearsToFortyNineYears': 'You are eligible for a free NHS flu vaccination from 3 October 2024 onwards',
  'cds.flu.result.header.eighteenYearsToFortyNineYears.eligible': 'You are eligible for NHS flu vaccination from 3 October 2024 onwards',
  'cds.flu.result.content.eighteenYearsToFortyNineYears.eligible': '<p>Your answers suggest that because of your work, you are eligible for an NHS flu vaccination.</p><p>This should be provided by your employer – please contact them for details.</p> <p>If you cannot get an NHS flu vaccination through your work, you may be able to get an NHS flu vaccination through your GP practice or through the NHS.</p><p>Please also note that flu vaccination may not be suitable for:</p> <ul><li><p>People with a severe egg allergy.</p></li><li><p>People who have had a severe allergic reaction to a previous dose of flu vaccine.</p> </li></ul>',
  
  // fiftyYearsToSixtyFourYears
  'cds.flu.result.header.fiftyYearsToSixtyFourYears.eligible': 'You are eligible for NHS flu vaccination from 3 October 2024 onwards',
  'cd.flu.result.content.fiftyYearsToSixtyFourYears.eligible': '<p>Your answers suggest that because of your work, you are eligible for an NHS flu vaccination.</p><p>This should be provided by your employer – please contact them for details.</p> <p>If you cannot get an NHS flu vaccination through your work, you may be able to get an NHS flu vaccination through your GP practice or through the NHS.</p><p>Please also note that flu vaccination may not be suitable for:</p> <ul><li><p>People with a severe egg allergy.</p></li><li><p>People who have had a severe allergic reaction to a previous dose of flu vaccine.</p> </li></ul>',
  // overSixtyFiveYears
  'cds.flu.result.header.overSixtyFiveYears': 'You are eligible for a free NHS flu vaccination from 3 October 2024 onwards',
  'cds.flu.result.content.overSixtyFiveYears': '<p>Your GP practice or the NHS will contact you to arrange your flu vaccination appointment. If you have not been contacted by end of October, please contact your GP practice.</p><p>Please also note that flu vaccination may not be suitable for:</p> <ul><li><p>People with a severe egg allergy.</p></li><li><p>People who have had a severe allergic reaction to a previous dose of flu vaccine. </p></li></ul>',
  // disclaimer
  'cds.flu.result.content.sixteenYearsToSeventeenYears.disclaimer': '<strong>Please note:</strong> <p>Pregnant women are eligible for an NHS flu vaccination from 1 September 2024. Please contact your practice if you are pregnant and have not heard from them by the end of September 2024.</p>',
  'cds.flu.result.content.eighteenYearsToFortyNineYears.disclaimer': '<strong>Please note:</strong> <p>Pregnant women are eligible for an NHS flu vaccination from 1 September 2024. Please contact your practice if you are pregnant and have not heard from them by the end of September 2024.</p>',
  'cds.flu.result.content.fiftyYearsToSixtyFourYears.disclaimer': '<strong>Please note:</strong> <p>Pregnant women are eligible for an NHS flu vaccination from 1 September 2024. Please contact your practice if you are pregnant and have not heard from them by the end of September 2024.</p>',
  // not suitable
  'cds.flu.result.header.sixteenYearsToSeventeenYears.notsuitable': 'Flu vaccination is not suitable for you',
  'cds.flu.result.content.sixteenYearsToSeventeenYears.notsuitable': '<p>Your answers suggest that you are not eligible for NHS flu vaccination.</p><p>In addition, flu vaccination cannot be given to people with a severe egg allergy or a history of severe reaction to a previous flu vaccination.</p>',

  'cds.flu.result.header.eighteenYearsToFortyNineYears.notsuitable': 'Flu vaccination is not suitable for you',
  'cds.flu.result.content.eighteenYearsToFortyNineYears.notsuitable': '<p>Your answers suggest that you are not eligible for NHS flu vaccination.</p><p>In addition, flu vaccination cannot be given to people with a severe egg allergy or a history of severe reaction to a previous flu vaccination.</p>',

  'cds.flu.result.header.fiftyYearsToSixtyFourYears.notsuitable': 'Flu vaccination is not suitable for you',
  'cds.flu.result.content.fiftyYearsToSixtyFourYears.notsuitable': '<p>Your answers suggest that you are not eligible for NHS flu vaccination.</p><p>In addition, flu vaccination cannot be given to people with a severe egg allergy or a history of severe reaction to a previous flu vaccination.</p>',

  // not eligible
  'cds.flu.result.header.sixteenYearsToSeventeenYears.noteligible': 'It looks like you are not eligible for a free NHS flu vaccination',
  'cds.flu.result.content.sixteenYearsToSeventeenYears.noteligible': '<p>The answers you have given indicate that you are not eligible for free NHS flu vaccination. If you still think you might be eligible, please contact your pharmacist or speak to your GP practice.</p> <h1>Book a private flu vaccination </h1> <p>You can still get a private flu vaccination by booking an appointment with a pharmacy on Patient Access now.</p><p>If you are currently unwell with a fever, please wait until you are fully recovered before booking your appointment.</p>',

  'cds.flu.result.header.eighteenYearsToFortyNineYears.noteligible': 'It looks like you are not eligible for a free NHS flu vaccination',
  'cds.flu.result.content.eighteenYearsToFortyNineYears.noteligible': '<p>The answers you have given indicate that you are not eligible for free NHS flu vaccination. If you still think you might be eligible, please contact your pharmacist or speak to your GP practice.</p> <h1>Book a private flu vaccination </h1> <p>You can still get a private flu vaccination by booking an appointment with a pharmacy on Patient Access now.</p><p>If you are currently unwell with a fever, please wait until you are fully recovered before booking your appointment.</p>',

  'cds.flu.result.header.fiftyYearsToSixtyFourYears.noteligible': 'It looks like you are not eligible for a free NHS flu vaccination',
  'cds.flu.result.content.fiftyYearsToSixtyFourYears.noteligible': '<p>The answers you have given indicate that you are not eligible for free NHS flu vaccination. If you still think you might be eligible, please contact your pharmacist or speak to your GP practice.</p> <p><strong>If you live in Scotland or Northern Ireland, you will be eligible for a flu vaccine via the NHS.</strong></p> <h1>Book a private flu vaccination </h1> <p>You can still get a private flu vaccination by booking an appointment with a pharmacy on Patient Access now.</p><p>If you are currently unwell with a fever, please wait until you are fully recovered before booking your appointment.</p>',

  // Cds: Landing page - asthma
  'cds.asthma.header': 'Consent to share and receive information',
  'cds.asthma.content': 'Because you are signed up to use Patient Access’s Medication Assistant service, we can offer you information about managing your asthma.',
  'cds.asthma.content1': 'We would be grateful if you would complete the questionnaire which has been funded and created in collaboration with AstraZeneca. This will help us understand how severe your asthma is and how well it is controlled at the moment. Knowing this will let us provide you with information which is most relevant to you. You\'ve already provided your consent to the Patient Access Medication Assistant service. However, we thought it would be helpful to provide this additional information to make the purpose of the service completely clear. You may withdraw your consent at any time if you no longer wish to use the service.',
  'cds.asthma.content2': 'Please note that the articles and details we provide are for information only. They are not an alternative to medical advice and in no way replace the advice being provided by your doctor or nurse.',
  'cds.asthma.button': 'Continue with Questionnaire',
  'cds.asthma.logo': 'Visit {link} for more patient related content. The website was created and funded by AstraZeneca. AstraZeneca has funded and provided input into the content of this questionnaire.',

  // Cds:ResultView
  'cds.result.header.underSixMonths': 'Seek GP advice',
  'cds.result.header.sixMonthsToTwoYearsOrElevenYearsToSeventeenYears': 'Seek GP advice',
  'cds.result.content.underSixMonths': 'We are sorry, but flu vaccination is not licensed for children aged under 6 months.',
  'cds.result.content.sixMonthsToTwoYearsOrElevenYearsToSeventeenYears': `${contents.sixMTo2YearsOr11To17Years1}${contents.sixMTo2YearsOr11To17Years2}${contents.sixMTo2YearsOr11To17Years3}`,
  'cds.result.header.twoYearsToElevenYears': 'Eligibility advice',
  'cds.result.content.twoYearsToElevenYears': `${contents.twoYearsToElevenYears1}${contents.twoYearsToElevenYears2}${contents.twoYearsToElevenYears3}`,

  'cds.result.header.sixMonthsToTwoYears': 'Seek GP advice',
  'cds.result.content.sixMonthsToTwoYears': 'Children under 2 years old are not routinely eligible for NHS flu vaccination.{br}{br}However, some children with underlying health conditions such as diabetes or conditions affecting the lungs, heart, kidneys, liver, nervous system or immune system may be eligible from 1 September 2024  onwards.{br}{br}If your child has an underlying health condition, please contact your GP practice to see if they need a flu vaccination.',

  'cds.result.header.twelveYearsToSeventeenYears': 'Seek GP practice advice',
  'cds.result.content.twelveYearsToSeventeenYears': `${contents.sixMTo2YearsOr11To17Years1}${contents.sixMTo2YearsOr11To17Years2}${contents.sixMTo2YearsOr11To17Years3}`,

  'cds.result.header.twelveYearsToSeventeenYears.noteligible': 'Not Eligible for free NHS flu vaccination',
  'cds.result.content.twelveYearsToSeventeenYears.noteligible': `${contents.sixMTo2YearsOr11To17Years2}`,
  'cds.result.content.twelveYearsToSeventeenYears.noteligible.self1': 'If you still think you might be eligible, please contact your pharmacist or speak to your GP practice.',
  'cds.result.content.twelveYearsToSeventeenYears.noteligible.other1': 'If you still think they might be eligible, please contact your pharmacist or speak to the GP practice of the person that you are inquiring about.',
  'cds.result.content.twelveYearsToSeventeenYears.noteligible.self2': 'If you are over 16 and have a Patient Access account, you can book an appointment for a private flu vaccination with your local pharmacy.',
  'cds.result.content.twelveYearsToSeventeenYears.noteligible.other2': 'You may wish to ask about the availability of proxy access at their practice. This will allow you book an appointment for them on their behalf at their practice or a pharmacy.',

  'cds.result.header.clinically.excluded': 'Seek GP advice',
  'cds.result.content.clinically.excluded.self1': 'It is not clear from your responses whether you qualify for NHS flu vaccination. Please contact your practice to enquire about your personal circumstances and whether you can get a flu vaccination.',
  'cds.result.content.clinically.excluded.self2': 'If you are currently unwell with a fever, please return and complete this questionnaire to book an appointment when you have recovered.',
  'cds.result.content.clinically.excluded.self3': 'If you have had a serious allergic reaction to the flu vaccine in the past, please contact your GP - it may be possible to arrange a flu vaccination for you with special precautions.',
  'cds.result.content.clinically.excluded.self4': 'You can still register with Patient Access and benefit from other services Patient Access has to offer.',
  'cds.result.content.clinically.excluded.other1': 'It is not clear from your responses whether they qualify for NHS flu vaccination. Please contact their practice to enquire about their personal circumstances and whether they can get a flu vaccination.',
  'cds.result.content.clinically.excluded.other2': 'If they are currently unwell with a fever, please return and complete this questionnaire to book an appointment when they have recovered.',
  'cds.result.content.clinically.excluded.other3': 'If they have had a serious allergic reaction to the flu vaccine in the past, please contact their GP - it may be possible to arrange a flu vaccination for them with special precautions.',
  'cds.result.content.clinically.excluded.other4': 'They can still register with Patient Access and benefit from other services Patient Access has to offer.',

  'cds.result.header.eligible.free': 'Eligible for a free NHS flu vaccination.',
  'cds.result.content.eligible.free': 'Please log in to book a flu appointment with your GP Practice. If your GP practice is not linked to your Patient Access account, you can also book a free NHS flu vaccination appointment with a pharmacy on Patient Access.',

  'cds.result.header.noteligible': 'It looks like you are not eligible for a free NHS flu vaccination.',
  'cds.result.content.noteligible.self1': 'The answers you have given indicate that you are not eligible for free NHS flu vaccination. ',
  'cds.result.content.noteligible.self2': 'If you still think you might be eligible, please contact your pharmacist for advice.',
  'cds.result.content.noteligible.other1': 'The answers you have given indicate that they are not eligible for free NHS flu vaccination. ',
  'cds.result.content.noteligible.other2': 'If you still think they might be eligible, please contact your pharmacist for advice.',
  'cds.result.content.noteligible.self3': 'Note: Frontline health and social care workers who have direct patient contact should be eligible for a flu vaccination through their employer – please contact your employer.',
  'cds.result.content.noteligible.other3': 'Note: Frontline health and social care workers who have direct patient contact should be eligible for a flu vaccination through their employer – please contact their employer.',
  'cds.result.content.noteligible.book.subtitle': 'Book a private flu vaccination',
  'cds.result.content.noteligible.book.content.self': 'You can still get a private flu vaccination by booking an appointment with a pharmacy on Patient Access now.',
  'cds.result.content.noteligible.book.content.other': 'They can still get a private flu vaccination by booking an appointment with a pharmacy on Patient Access now.',
  'cds.result.content.cost': 'The cost of a private flu vaccination starts from £9.99.',

  'cds.result.header.noteligible.fiftyToSixtyFourYears': 'Eligible from 1 December 2020 for a free NHS flu vaccination. (England only).',
  'cds.result.header.noteligible.fiftyToSixtyFourYears.content.self': 'Please log in to book a flu appointment with your GP practice. If your practice is not linked to your Patient Access account, you can also book a free NHS flu vaccination appointment with a pharmacy on Patient Access.',
  'cds.result.header.noteligible.fiftyToSixtyFourYears.content.other': 'Please log in to book a flu appointment with their GP practice. If their practice is not linked to their Patient Access account, you can also book a free NHS flu vaccination appointment with a pharmacy on Patient Access.',
  'cds.result.content.noteligible.fiftyToSixtyFourYears.scotland.info.self': 'You can find out more about eligibility in Scotland if you are {link55to64} or {link50to54}.',
  'cds.result.content.noteligible.fiftyToSixtyFourYears.scotland.info.other': 'You can find out more about eligibility in Scotland if they are {link55to64} or {link50to54}.',
  'cds.result.content.noteligible.fiftyToSixtyFourYears.scotland.info.link55to64': '55-64',
  'cds.result.content.noteligible.fiftyToSixtyFourYears.scotland.info.link50to54': '50-54',

  'cds.result.content.corona.self': 'Please note that if you have a fever, new continuous cough or loss of (or change to) your sense of taste or smell, you should not leave your home. You should use {link} as soon as possible for advice on what action to take.{br}{br}Please note that to protect other patients, a suitable face covering must be worn when attending the practice or pharmacy.',
  'cds.result.content.corona.other': 'Please note that if they have a fever, new continuous cough or loss of (or change to) their sense of taste or smell, they should not leave their home. You should use {link} as soon as possible for advice on what action to take.{br}{br}Please note that to protect other patients, a suitable face covering must be worn when attending the practice or pharmacy.',
  'cds.result.content.corona.link': 'this online symptom checker',

  'cds.result.content.twelveYearsToSeventeenYears.noteligible.pregnant.self': 'You are pregnant.',
  'cds.result.content.twelveYearsToSeventeenYears.noteligible.pregnant.other': 'They are pregnant.',
  'cds.result.content.twelveYearsToSeventeenYears.noteligible.carer.self': 'You are over 16 and a carer receiving carer\'s allowance.',
  'cds.result.content.twelveYearsToSeventeenYears.noteligible.carer.other': 'They are over 16 and a carer receiving carer\'s allowance.',
  'cds.result.content.twelveYearsToSeventeenYears.noteligible.bmi.self': 'You are severely obese with a BMI (body mass index) over 40.',
  'cds.result.content.twelveYearsToSeventeenYears.noteligible.bmi.other': 'They are severely obese with a BMI (body mass index) over 40.',

  // Cds: ResultView Button
  'cds.result.button.go.backtohome.flu': 'Back to home',
  'cds.result.button.go.home.flu': 'Close',
  'cds.result.button.header1': 'New to Patient Access?',
  'cds.result.button.header2': 'Already have a Patient Access account?',
  'cds.result.button.register': 'Register',
  'cds.result.button.signin': 'Sign in',
  'cds.result.button.register.book': 'Register to book it now',
  'cds.result.button.signin.book': 'Sign in to book it now',

  'cds.error': 'Sorry unexpected error occured. Please try later.',
  'cds.result.button.go.home': 'Go to Patient Access home',

  'cds.coverpage.content.privateFlu.desktop': 'Looking for a private flu vaccination? {br}',
  'cds.coverpage.content.privateFlu.mobile': 'Looking for a private flu vaccination? ',
  'cds.coverpage.content.privateFluBook.linkText': 'Book it now',
  'cds.coverpage.content.privateFluBook': '{bookNow}',

  // Cds: triage button
  'cds.triage.button.exit': 'Exit',
  'cds.triage.button.submit': 'Submit answers',
  'cds.triage.button.createreferral': 'Create Referral',
  'cds.triage.button.patientdecilned': 'Patient Declined',
};

export default cdsMessages;
