import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import ExternalLink from './Outcome/Elements/ExternalLink';
import AgeGroupRestriction from './Outcome/AgeGroupRestriction';
import FluAgeGroupRestriction from './FluAgeGroupRestriction';
import ClinicallyExcluded from './Outcome/ClinicallyExcluded';
import NhsFreeEligible from './Outcome/NhsFreeEligible';
import NotEligible from './Outcome/NotEligible';
import { AGE_GROUP_RESTRICTION, CORONACHECKERURL } from '../../constant';

const ResultView = ({ result, isFlu }) => {
  const renderCommonMessage = (subject) => (
    <strong>
      <FormattedMessage id={`cds.result.content.corona.${subject}`}
        values={{
          br: <br />,
          link: <ExternalLink link={CORONACHECKERURL} id="cds.result.content.corona.link" />,
        }}
        defaultMessage=""
      />
    </strong>
  );

  const { submissionId, properties } = result;
  const { ageGroupRestriction, clinicallyExcluded, nhsFreeEligible, subject } = properties;
  if (isFlu) {
    return <FluAgeGroupRestriction properties={properties} submissionId={submissionId} />;
  }
  if (ageGroupRestriction !== AGE_GROUP_RESTRICTION.NORESTRICTION && ageGroupRestriction !== AGE_GROUP_RESTRICTION.FIFTYYEARSTOSIXTYFOURYEARS) {
    return (
      <AgeGroupRestriction submissionId={submissionId} properties={properties} >
        {renderCommonMessage(subject)}
      </AgeGroupRestriction>
    );
  }
  else if (clinicallyExcluded) {
    return (
      <ClinicallyExcluded submissionId={submissionId} subject={subject} >
        {renderCommonMessage(subject)}
      </ClinicallyExcluded>
    );
  }
  else if (nhsFreeEligible) {
    return (
      <NhsFreeEligible submissionId={submissionId} >
        {renderCommonMessage(subject)}
      </NhsFreeEligible>
    );
  }
  else {
    return (
      <NotEligible submissionId={submissionId} subject={subject} ageGroupRestriction={ageGroupRestriction} >
        {renderCommonMessage(subject)}
      </NotEligible>
    );
  }
}

ResultView.propTypes = {
  result: PropTypes.shape({
    properties: PropTypes.shape({
      ageGroupRestriction: PropTypes.string,
      clinicallyExcluded: PropTypes.bool,
      nhsFreeEligible: PropTypes.bool,
      subject: PropTypes.string,
    }).isRequired,
    submissionId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }).isRequired,
  isFlu: PropTypes.bool,
};

export default ResultView;
