import React from 'react';
import PropTypes from 'prop-types';

const OutcomeBody = ({ children }) => {
  return (
    <div className="cds-content">
      <div className="cds-content-p">
        {children}
      </div>
    </div>
  );
};

OutcomeBody.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OutcomeBody;