import React, { Fragment} from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { redirectUrl } from '../../../sideEffects/urlMapper';
import OutcomeWrapper from './Elements/OutcomeWrapper';
import OutcomeTitle from './Elements/OutcomeTitle';
import OutcomeBody from './Elements/OutcomeBody';
import OutcomeButton from './Elements/OutcomeButton';
import OutcomeButtonHeader from './Elements/OutcomeButtonHeader';
import { AGE_GROUP_RESTRICTION, SCOTLAND_ELIGIBILITY } from '../../../constant';
import ExternalLink from './Elements/ExternalLink';

const renderRegisterAndLoginButtons = (submissionId) => {
  const registerUrl = redirectUrl('registration/create', submissionId, false);
  const loginUrl = redirectUrl('login', submissionId, false);
  return (
    <Fragment>
      <OutcomeButtonHeader headerId="cds.result.button.header1">
        <OutcomeButton type="primary" messageKey="cds.result.button.register.book" link={registerUrl} />
      </OutcomeButtonHeader>
    
      <OutcomeButtonHeader headerId="cds.result.button.header2">
        <OutcomeButton type="outline" messageKey="cds.result.button.signin.book" link={loginUrl} />
      </OutcomeButtonHeader>
    </Fragment>
  );
}

const NotEligible = ({ submissionId, subject, children, ageGroupRestriction }) => {

  if (ageGroupRestriction === AGE_GROUP_RESTRICTION.FIFTYYEARSTOSIXTYFOURYEARS) {
    return (
      <OutcomeWrapper>
        <OutcomeTitle id="cds.result.header.noteligible.fiftyToSixtyFourYears" defaultMessage="Eligible from 1st December 2020 for a free NHS flu vaccination." />
        <OutcomeBody>
          <strong>
            <FormattedMessage
              id={`cds.result.content.noteligible.fiftyToSixtyFourYears.scotland.info.${subject}`}
              values={{
                link55to64: <ExternalLink link={SCOTLAND_ELIGIBILITY.FIFTYFIVETOSIXTYFOUR_AGEGROUP} id="cds.result.content.noteligible.fiftyToSixtyFourYears.scotland.info.link55to64" />,
                link50to54: <ExternalLink link={SCOTLAND_ELIGIBILITY.FIFTYTOFIFTYFOUR_AGEGROUP} id="cds.result.content.noteligible.fiftyToSixtyFourYears.scotland.info.link50to54" />,
              }}
              defaultMessage=""
            />
          </strong>
          <br /><br />
          <FormattedMessage id={`cds.result.header.noteligible.fiftyToSixtyFourYears.content.${subject}`} defaultMessage="" />
          <br /><br />
          {children}
        </OutcomeBody>
        {renderRegisterAndLoginButtons(submissionId)}
      </OutcomeWrapper>
    );
  }

  return (
    <OutcomeWrapper>
      <OutcomeTitle id="cds.result.header.noteligible" defaultMessage="It looks like you are not eligible for a free NHS flu vaccination." />
      <OutcomeBody>
        <FormattedMessage id={`cds.result.content.noteligible.${subject}1`} defaultMessage="Sorry something went wrong." />
        <FormattedMessage id={`cds.result.content.noteligible.${subject}2`} defaultMessage="" />
        <br /><br />
        <FormattedMessage id={`cds.result.content.noteligible.${subject}3`} defaultMessage="" />
        <br /><br />
        <div className="cds-resultview-subtitle">
          <h3>
            <FormattedMessage id="cds.result.content.noteligible.book.subtitle" defaultMessage="Book a private flu vaccination" />
          </h3>
        </div>
        <br />
        <FormattedMessage id={`cds.result.content.noteligible.book.content.${subject}`} defaultMessage="" />
        <br /><br />
        <FormattedMessage id="cds.result.content.cost" defaultMessage="The cost of a private flu vaccination starts from £9.99." />
        <br /> <br />
        {children}
      </OutcomeBody>
      {renderRegisterAndLoginButtons(submissionId)}
    </OutcomeWrapper>
  );
};

NotEligible.propTypes = {
  submissionId: PropTypes.string,
  subject: PropTypes.string,
  children: PropTypes.node,
  ageGroupRestriction: PropTypes.string,
};

export default NotEligible;