import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FormItem, Checkbox as UiCheckbox } from '@patient-access/ui-kit';

import FieldLabel from './FieldLabel';
import { renderValidationMessage } from '../Helpers/ui';

class MultiChoice extends Component {
  render() {
    const { field, changeHandler, selectedValue, fieldNumber } = this.props;
    return (
      <FormItem type="item" error={field.error} dataId={`form-MultiChoice-${field.id}`}>
        <div className="cds-step-header">
          <FieldLabel field={field} fieldNumber={fieldNumber} faux />
        </div>
        <div className="cds-step-content">
          {Object.entries(field.choices[0]).map(choice => (
            <UiCheckbox className=""
              key={choice[0]}
              id={`${field.id}_${choice[0]}`}
              name={`${field.id}_${choice[0]}`}
              value={choice[0] || ''}
              onChange={event => changeHandler(event.target.value, field.id, choice[0])}
              checked={selectedValue.value === choice[0]}
              label={choice[1]}
              type='card'
            />
          ))}
        </div>
        {renderValidationMessage(field.error)}
      </FormItem>
    );
  }
}

MultiChoice.propTypes = {
  field: PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    options: PropTypes.shape({
      label: PropTypes.string,
      choices: PropTypes.object,
    }),
    error: PropTypes.string,
  }).isRequired,
  changeHandler: PropTypes.func.isRequired,
};

export default MultiChoice;
