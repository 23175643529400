import { addLocaleData } from 'react-intl';

import enLocale from 'react-intl/locale-data/en';
import esLocale from 'react-intl/locale-data/es';

import enGB from './en';
import es from './es';

/* eslint-disable */
addLocaleData([...enLocale, ...esLocale]);
/* eslint-enable */

export default {
  es,
  'en-GB': enGB,
  en: enGB,
};
