import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { Loader } from '@patient-access/ui-kit';

import { useStateValue } from '../../context';
import { getCoronaFormData } from '../../data';
import { APPIDS, PA_SCREENS } from '../../constant';
import TriageForm from '../../components/Triage/TriageForm';
import Button from '../../components/Triage/TriageOverlayButton';
import { doSubmitForm, doStartForm, doCloseOverlay } from '../../context/dispatcher';
import { postMessageToParent } from '../../components/Common/Helpers';

const CoronaFormView = ({ location }) => {
    const formName = 'corona';
    const [inputFormData, setInputFormData] = useState(null);
    const [state, dispatch] = useStateValue();
    const { overlay } = state.triage || {};

    useEffect(() => {
        const data = getCoronaFormData();
        setInputFormData(data);
    }, []);

    const submissionHandler = async (data) => {
        const submissionResult = await doSubmitForm(location, formName)(state, dispatch);
        if (submissionResult && submissionResult.submissionId) {
            const messageToParentApp = Object.assign({}, submissionResult, data);
            postMessageToParent(messageToParentApp);
            const { appid } = parse(location && location.search);
            if (APPIDS.PATIENTACCESS === appid) {
                return;
            }
        }
        doStartForm()(dispatch);
    };

    const handleExitButtonClick = async (e) => {
        if (!(overlay && overlay.isRedFlag)) {
            doCloseOverlay()(dispatch);
            
            return;
        }

        const responseData = {
            isExit: true,
            navigateTo: PA_SCREENS.HOME,
        };
        await submissionHandler(responseData);
    };

    const renderOverlayButtons = () => (
        <Button buttonType="primary"
            messageKey="cds.triage.button.exit"
            onClick={handleExitButtonClick} />
    );

    if (!inputFormData) {
        return <Loader type="block" size="medium" className="cds-loader" />
    }

    return (
        <TriageForm inputFormData={inputFormData}>
            {renderOverlayButtons()}
        </TriageForm>
    );
}

CoronaFormView.propTypes = {
    location: PropTypes.any,
};
export default CoronaFormView;
