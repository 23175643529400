import React, { Component } from 'react';
import { parse } from 'query-string';
import CoverPageView from '../components/Common/CoverPageView';
import FormPage from '../components/Flu/FormPage';
import { WIDGET_TYPE } from './../constant.js';

class FluWidgetView extends Component {
  constructor(props) {
    super(props);
    this.onContinueClick = this.onContinueClick.bind(this);
    this.state = {
      buttonStatus: false,
    };
  }

  onContinueClick(e) {
    e.preventDefault();
    this.setState({
      buttonStatus: true,
    });
  }

  renderFormPage = (searchLocation, pathName) => {
    const { appid, sessionid, trackingid, utm_source } = searchLocation || {};
    let id = trackingid || sessionid;

    return (
          <FormPage
            appId={appid}
            sessionId={id}
            utmSource={utm_source}
          />
       );
  }

  renderCoverPage = (searchLocation, pathName) => {
    const { appid, appversion } = searchLocation || {};

    return (
      <div className="cds-wrapper cds-wrapper-medium">
        <CoverPageView
          onContinueClick={this.onContinueClick}
          appId={appid}
          appVersion={appversion}
          widgetType={WIDGET_TYPE.FLU}
        />
      </div>
    );
  }

  render() {
    const { location } = this.props;
    let searchLocation;

    if (location && location.search) {
      searchLocation = parse(location.search);
    }

    if (this.state.buttonStatus) {
      return this.renderFormPage(searchLocation, location.pathname)
    }

    return this.renderCoverPage(searchLocation, location.pathname);
  }
}
export default FluWidgetView;
