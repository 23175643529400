import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FormItem, Radio as UiRadio } from '@patient-access/ui-kit';

import FieldLabel from './FieldLabel';
import { renderClearSelection, renderValidationMessage } from '../Helpers/ui';
import HeaderElement from './HeaderElement';
import ContentElement from './ContentElement';

class Radio extends Component {
  render() {
    const { field, changeHandler, fieldNumber } = this.props;
    return (
      <FormItem type="item" error={field.error} dataId={`form-radio-${field.id}`}>
        <HeaderElement>
          <FieldLabel field={field} fieldNumber={fieldNumber} faux />
        </HeaderElement>
        <ContentElement>
          {Object.entries(field.options.choices).map(choice => (
            <UiRadio
              className={field.value === choice[0] ? 'input-radio-checked' : ''}
              key={choice[0]}
              id={`${field.id}_${choice[0]}`}
              name={field.id}
              value={choice[0]}
              onChange={event => changeHandler(event.target.value, field.id)}
              checked={field.value === choice[0]}
              label={choice[1].displayValue}
              type="card"
            />
          ))}
        </ContentElement>
        {renderValidationMessage(field.error)}
        {renderClearSelection(field, changeHandler)}
      </FormItem>
    );
  }
}

Radio.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    options: PropTypes.shape({
      label: PropTypes.string,
      choices: PropTypes.object,
    }),
    error: PropTypes.string,
  }).isRequired,
  changeHandler: PropTypes.func.isRequired,
};

export default Radio;
