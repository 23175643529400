import React from 'react';
import PropTypes from 'prop-types';

const OutcomeWrapper = ({ children }) => {
  return (
    <div className="cds-resultview-content">
        {children}
    </div>
  );
};

OutcomeWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OutcomeWrapper;