import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

const Disclaimer = ({
  id, defaultMessage,
}) => {
  return (
    <div className="cds-disclaimer">
      <FormattedHTMLMessage
        tagName="span"
        id={id}
        defaultMessage={defaultMessage}
      />
    </div>
  );
};

export default Disclaimer;
