import { parse } from 'query-string';
import adaptFormForCds from './utils/formSubmitAdapter';
import { getUserFormPages } from './selectors';
import { cdsFormSubmit } from '../sideEffects/cdsDispatcher';
import { getQuestionnaireId } from '../components/Common/Helpers/envService';
import {
    fetchFormSuccess,
    navigateForward,
    resetForm,
    isSynchronising,
    setFormValue,
    validatePage,
    closeOverlay,
} from './actions';

const doLoadForm = inputFormData => dispatch => {
    dispatch(fetchFormSuccess(inputFormData));
};

const doStartForm = () => dispatch => {
    dispatch(resetForm());
    dispatch(navigateForward(0));
};

const doHandleFormValueChange = (value, fieldId, choiceId, pageId) => dispatch => {
    dispatch(setFormValue(value, fieldId, pageId, choiceId));
};

const doHandleContinueClick = pageId => dispatch => {
    dispatch(validatePage(pageId));
    dispatch(navigateForward(pageId + 1));
};

const doIsSynchronising = value => dispatch => {
    dispatch(isSynchronising(value));
};

const doCloseOverlay = () => dispatch => {
    dispatch(closeOverlay());
}

const doSubmitForm = (location, formName) => async (state, dispatch) => {
    dispatch(isSynchronising(true));
    const userFormPages = getUserFormPages(state);
    const adaptedForm = adaptFormForCds(userFormPages);
    const { appid, sessionid, utm_source } = parse(location && location.search);
    const params = {
        sessionId: sessionid,
        appId: appid,
        pageName: utm_source,
    };
    const questionnaireId = getQuestionnaireId(formName);

    try {
        const result = await cdsFormSubmit(questionnaireId, adaptedForm, params);
        return result;
    }
    catch {
        return null;
    }
};

export {
    doLoadForm,
    doStartForm,
    doSubmitForm,
    doCloseOverlay,
    doIsSynchronising,
    doHandleFormValueChange,
    doHandleContinueClick,
};

