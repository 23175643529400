const actionTypes = {
    FETCH_FORM_SUCCESS: 'triage.forms/FETCH_FORM_SUCCESS',
    NAVIGATE_FORWARD: 'triage.forms/NAVIGATE_FORWARD',
    SET_FORM_VALUE: 'triage.forms/SET_FORM_VALUE',
    VALIDATE_PAGE: 'triage.forms/VALIDATE_PAGE',
    RESET_FORM: 'triage.forms/RESET_FORM',
    CLOSE_OVERLAY: 'triage.forms/CLOSE_OVERLAY',
    IS_PAGE_VALID: 'triage.forms/IS_PAGE_VALID',
    IS_SYNCHRONISING: 'triage.forms/IS_SYNCHRONISING',
  };
  
  export default actionTypes;
  