import React from 'react';
import PropTypes from 'prop-types';
import { Button as UIButton } from '@patient-access/ui-kit';

const OutcomeButton = ({ type, messageKey, link }) => {
  return (
    <UIButton
      buttonType={type}
      messageKey={messageKey}
      defaultMessage="click here"
      data-id="outcome-button"
      buttonSize="medium"
      onClick={(e) => {
        e.preventDefault();
        window.open(link, '_parent');
      }}
    />
  );
};

OutcomeButton.propTypes = {
  type: PropTypes.string.isRequired,
  messageKey: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default OutcomeButton;
