import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import MediaQuery from 'react-responsive';

const FluCoverPageDescription = ({ isFlu, canShowBookPrivateFlu, getPrivateFluLink, isInPatientAccessApp }) => {
    const bookPrivateFluMobile = () => {
        if (!canShowBookPrivateFlu) {
            return null;
        }
        const bookNow = getPrivateFluLink;

        return (
            <MediaQuery maxWidth={768}>
                <div className="cds-content cds-content-booknow">
                    <p tabIndex={0} role="text">
                        <strong><FormattedMessage id="cds.coverpage.content.privateFlu.desktop" values={{ br: <br /> }} defaultMessage="Looking for a private flu vaccination? {br}" /></strong>
                        <strong><FormattedMessage id="cds.coverpage.content.privateFluBook" defaultMessage="{bookNow}" values={{ bookNow }} /></strong>
                    </p>
                </div>
            </MediaQuery>
        );
    }

    return (
        <div className="cds-body-content">
            <div className="cds-title-header">
                <h1 tabIndex={0} role="text"><FormattedMessage id="cds.header" defaultMessage="Find out if you are eligible for a free NHS flu vaccination" /></h1>
            </div>

            <div className="cds-sub-header">
                <h3 tabIndex={0} role="text"><FormattedMessage id="cds.subheader1" defaultMessage="About flu vaccination" /></h3>
            </div>
            <div className="cds-content">
                <p tabIndex={0} role="text">
                    {
                        isFlu
                            ? <FormattedMessage id="cds.content1.about" values={{ br: <br /> }} defaultMessage="Content" />
                            : <FormattedMessage id="cds.content1" values={{ br: <br /> }} defaultMessage="Content" />
                    }
                </p>
            </div>
            <div className="cds-sub-header">
                <h3 tabIndex={0} role="text"><FormattedMessage id="cds.subheader2" defaultMessage="Free NHS flu vaccination" /></h3>
            </div>
            <div className="cds-content">
                <p tabIndex={0} role="text">
                    {
                        isFlu
                            ? <FormattedHTMLMessage id="cds.content1.flu" tagName="div"
                                defaultMessage="Content" />
                            : <FormattedMessage id="cds.content1.1.flu" values={{ br: <br /> }} defaultMessage="Content" />
                    }
                </p>
                <p tabIndex={0} role="text">
                    {
                        isFlu
                            ? <FormattedMessage id="cds.content2.flu" values={{ br: <br /> }} defaultMessage="Content" />
                            : <FormattedMessage id="cds.content2" values={{ br: <br /> }} defaultMessage="Content" />
                    }
                </p>
                <p tabIndex={0} role="text">
                    {
                        isFlu
                            ? (isInPatientAccessApp ? <FormattedMessage id="cds.content3.flu" values={{ br: <br /> }} defaultMessage="Content" /> : <FormattedMessage id="cds.content3.flu.notSignedIn" values={{ br: <br /> }} defaultMessage="Content" />)
                            : <FormattedMessage id="cds.content3" values={{ br: <br /> }} defaultMessage="Content" />
                    }
                </p>
                <p tabIndex={0} role="text"><strong><FormattedMessage id="cds.content4" defaultMessage="Content" /></strong> </p>
            </div>
            {bookPrivateFluMobile()}
        </div>
    );
}

FluCoverPageDescription.propTypes = {
    isFlu: PropTypes.bool,
    canShowBookPrivateFlu: PropTypes.bool,
    getPrivateFluLink: PropTypes.any,
    isInPatientAccessApp: PropTypes.bool,
};

export default FluCoverPageDescription;