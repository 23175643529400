import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { redirectUrl } from '../../../sideEffects/urlMapper';
import OutcomeWrapper from './Elements/OutcomeWrapper';
import OutcomeTitle from './Elements/OutcomeTitle';
import OutcomeBody from './Elements/OutcomeBody';
import OutcomeButton from './Elements/OutcomeButton';
import OutcomeButtonHeader from './Elements/OutcomeButtonHeader';

const NhsFreeEligible = ({ submissionId, children }) => {
  const registerUrl = redirectUrl('registration/create', submissionId, false);
  const loginUrl = redirectUrl('login', submissionId, false);

  return (
    <OutcomeWrapper>
      <OutcomeTitle id="cds.result.header.eligible.free" defaultMessage="Eligible for a free NHS flu vaccination." />
      <OutcomeBody>
        <FormattedMessage id="cds.result.content.eligible.free" defaultMessage="Please log in to book a flu appointment with your GP Practice. If your GP practice is not linked to your Patient Access account, you can also book a free NHS flu vaccination appointment with a pharmacy on Patient Access." />
        <br /> <br />
        {children}
      </OutcomeBody>

      <OutcomeButtonHeader headerId="cds.result.button.header1">
        <OutcomeButton type="primary" messageKey="cds.result.button.register.book" link={registerUrl} />
      </OutcomeButtonHeader>

      <OutcomeButtonHeader headerId="cds.result.button.header2">
        <OutcomeButton type="outline" messageKey="cds.result.button.signin.book" link={loginUrl} />
      </OutcomeButtonHeader>
    </OutcomeWrapper>
  );
};

NhsFreeEligible.propTypes = {
  submissionId: PropTypes.string,
  children: PropTypes.node,
};

export default NhsFreeEligible;