import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const PageExitPrompt = ({children, noExitPrompt}) => {   

  useEffect(() => {
    window.addEventListener("beforeunload", onUnload);
    return () => {
      window.removeEventListener("beforeunload", onUnload);
    }
  }, []);

  const onUnload = e => { // the method that will be used for both add and remove event
    if (!noExitPrompt)
    {
      e.preventDefault();
      e.returnValue = '';
    } 
  }   
      
  return (
    <>
      {children}
    </>
  );
}

PageExitPrompt.propTypes = {
    children: PropTypes.any,
    noExitPrompt: PropTypes.bool,
  };

export default PageExitPrompt;



