import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { redirectUrl } from '../../../sideEffects/urlMapper';
import OutcomeWrapper from './Elements/OutcomeWrapper';
import OutcomeTitle from './Elements/OutcomeTitle';
import OutcomeBody from './Elements/OutcomeBody';
import OutcomeButton from './Elements/OutcomeButton';
import OutcomeButtonHeader from './Elements/OutcomeButtonHeader';
 
const ClinicallyExcluded = ({ submissionId, subject, children }) => {
  const registerUrl = redirectUrl('registration/create', submissionId, true);
  const loginUrl = redirectUrl('login', submissionId, true);

  return (
    <OutcomeWrapper>
      <OutcomeTitle id="cds.result.header.clinically.excluded" defaultMessage="Seek GP advice" />
      <OutcomeBody>
        <FormattedMessage id={`cds.result.content.clinically.excluded.${subject}1`} defaultMessage="" />
        <br /> <br />
        <FormattedMessage id={`cds.result.content.clinically.excluded.${subject}2`} defaultMessage="" />
        <br /> <br />
        {children}
        <br /> <br />
        <FormattedMessage id={`cds.result.content.clinically.excluded.${subject}3`} defaultMessage="" />
        <br /> <br />
        <FormattedMessage id={`cds.result.content.clinically.excluded.${subject}4`} defaultMessage="" />
      </OutcomeBody>

      <OutcomeButtonHeader headerId="cds.result.button.header1">
        <OutcomeButton type="primary" messageKey="cds.result.button.register" link={registerUrl} />
      </OutcomeButtonHeader>

      <OutcomeButtonHeader headerId="cds.result.button.header2">
        <OutcomeButton type="outline" messageKey="cds.result.button.signin" link={loginUrl} />
      </OutcomeButtonHeader>
    </OutcomeWrapper>
  );
};

ClinicallyExcluded.propTypes = {
  submissionId: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default ClinicallyExcluded;